export interface Action<T> {
    (T): any;
}

export class ActionAndContext<T> {
    constructor(public fn: Action<T>, public ctx: any, public args: T) { }

    Execute(): void {
        if (this.ctx) {
            this.fn.call(this.ctx, this.args);
        }
        else {
            this.fn(this.args);
        }
    }
}

export class ActionAndContextWithInstanceId<T> extends ActionAndContext<T> {
    constructor(fn: Action<T>, ctx: any, args: T, public instanceId: number) {
        super(fn, ctx, args);
    }

    override Execute(): void {
        if (this.ctx) {
            this.fn.call(this.ctx, this.args);
        }
        else {
            this.fn(this.args);
        }
    }
}