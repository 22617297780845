import { Component, OnInit, OnDestroy, Inject, NgModule } from "@angular/core";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { MeStateChangedMessageData, ShowInAppToastMessageData } from "src/app/infrastructure/message.data.types";
import { SESSION_STORAGE_KEYS } from "src/app/infrastructure/storage.keys";
import { ConfirmEmailMutation } from "src/app/graphql/mutations/ConfirmEmailMutation";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { BrowserStorageService } from "src/app/services/browser.storage.module/browser.storage.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { GraphQLService } from "src/app/services/graphql.module/graphql.service";
import { Router } from "@angular/router";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";


@Component({
    selector: 'confirm-email-banner',
    templateUrl: './confirm-email-banner.component.html',
    styleUrls: ['./confirm-email-banner.component.scss'],
    standalone: true,
    imports: [PbIconComponent]
})

export class ConfirmEmailBannerComponent implements OnInit, OnDestroy {
    private meStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
        On: (data: MeStateChangedMessageData) => {
            this.visible = false;
            if (!this.platformService.IsBrowserPlatform) { return; }
            if (data && data.me && data.me.email
                && this.browserStorageService.GetSession(SESSION_STORAGE_KEYS.SUPPRESS_CONFIRM_EMAIL) !== true
                && data.me.hasConfirmedEmail === false
            ) {
                this.currentEmail = data.me.email;
                this.visible = true;
                return;
            }
        }
    }

    private set currentEmail(value: string) {
        this.text1 = "Please go to {EMAIL} to confirm your email".replace("{EMAIL}", value);
    }

    text1: string;
    visible: boolean;

    constructor(
        private messenger: Messenger,
        private browserStorageService: BrowserStorageService,
        private platformService: PlatformHelpersService,
        private gqlService: GraphQLService,
        private router:Router
    ) {
        this.messenger.Subscribe(this.meStateChangedSubscriber);
        this.visible = false;
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.meStateChangedSubscriber);
    }

    OnClickedResendEmail(): void {
        this.ExecuteResendEmailMutation();
    }

    OnClickedChangeAddress(): void {
        this.SetHiddenInSessionStorage();
        this.router.navigateByUrl('/account');
    }

    OnClickedX(): void {
        this.SetHiddenInSessionStorage();
    }

    private SetHiddenInSessionStorage() {
        this.visible = false;
        this.browserStorageService.SetSession(SESSION_STORAGE_KEYS.SUPPRESS_CONFIRM_EMAIL, true);
    }

    private ExecuteResendEmailMutation() {
        this.gqlService.ExecuteMutation(ConfirmEmailMutation, null, this.ExecuteResendEmailMutation, this).then(() => {
            this.SetHiddenInSessionStorage();
            this.messenger.Send({
                messageType: MESSAGE_TYPES.SHOW_IN_APP_TOAST,
                messageData: new ShowInAppToastMessageData("Email sent")
            });
        });
    }
}
