<div class="pb-footer-wrapper">
    <div class="pb-footer">
        <div class="footer-content">
            <div class="footer-links">
                <div class="footer-group">
                    <span class="group-title">Placebuzz</span>
                    <a routerLink="/how-it-works">How it works</a>
                    <a routerLink="/share/getapp">Mobile app</a>
                    <a routerLink="/press">Press</a>
                </div>

                <div class="footer-group">
                    <span class="group-title">Let us help</span>
                    <a routerLink="/faq">FAQs</a>
                    <a routerLink="/feedback">Feedback</a>
                    <a routerLink="/contact-us">Contact us</a>
                </div>

                <div class="footer-group">
                    <span class="group-title">Advertising</span>
                    <a routerLink="/advertise-your-property">List properties</a>
                    <a routerLink="/display-advertising">Display ads</a>
                </div>
            </div>

            <div class="social-links">
                <span class="social-counter">15k Followers</span> 
                <a class="social-icon instagram" href="https://www.instagram.com/placebuzz/" target="_blank"></a>
                <a class="social-icon facebook" href="https://www.facebook.com/placebuzz/" target="_blank"></a>
                <a class="social-icon twitter" href="https://twitter.com/placebuzz" target="_blank"></a>
                <a class="social-icon tiktok" href="https://www.tiktok.com/@placebuzz" target="_blank"></a>
            </div>

            <div class="copyright-footer">
                <span class="copyright">&#64;{{currentYear}} Placebuzz Limited</span>
                <a routerLink="/privacy">Privacy Policy</a>
                <a routerLink="/cookies">Cookies</a>
                <a routerLink="/terms">Terms of use</a>
            </div>
        </div>
    </div>    
</div>