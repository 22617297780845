export var adDefinitions = [
    {page:"search_results", adPosition:"result_header", device:"mobile", fuseId:"23042458289"},
    {page:"search_results", adPosition:"result_incontent_2", device:"desktop, mobile", fuseId:"23041929889"},
    {page:"search_results", adPosition:"result_incontent_3", device:"desktop, mobile", fuseId:"23041929895"},
    {page:"search_results", adPosition:"result_incontent_1", device:"desktop, mobile", fuseId:"23041929238"},
    {page:"search_results", adPosition:"result_incontent_5", device:"desktop", fuseId:"23042458292"},
    {page:"search_results", adPosition:"result_incontent_4", device:"desktop, mobile", fuseId:"23041929901"},
    // {page:"search_results", adPosition:"result_vrec_lhs", device:"desktop", fuseId:"23041929904"},
    // {page:"search_results", adPosition:"result_vrec_rhs", device:"desktop", fuseId:"23041929907"},
    {page:"search_results", adPosition:"result_vrec_1", device:"desktop", fuseId:"23041929241"},

    {page:"property_details", adPosition:"property_header", device:"desktop, mobile", fuseId:"22119283214"},
    {page:"property_details", adPosition:"property_incontent_1", device:"desktop, mobile", fuseId:"22119588000"},
    {page:"property_details", adPosition:"property_incontent_2", device:"desktop, mobile", fuseId:"22119283223"},
    {page:"property_details", adPosition:"property_vrec_1", device:"desktop", fuseId:"22119283226"},

    {page:"search", adPosition:"search_header", device:"mobile", fuseId:"22119283181"},
    {page:"search", adPosition:"search_incontent_1", device:"mobile", fuseId:"22119283184"},
    {page:"search", adPosition:"search_vrec_rhs", device:"desktop", fuseId:"23041929223"},
    {page:"search", adPosition:"search_vrec_lhs", device:"desktop", fuseId:"23041929808"},

    {page:"home", adPosition:"home_header", device:"desktop, mobile", fuseId:"22119283217"},

    {page:"guide", adPosition:"guide_incontent_1", device:"desktop, mobile", fuseId:"23041929880"},
    {page:"guide", adPosition:"guide_header", device:"desktop, mobile", fuseId:"23041929877"},
    {page:"guide", adPosition:"guide_incontent_2", device:"desktop, mobile", fuseId:"23041929883"},

    {page:"administrative_area", adPosition:"buy_header", device:"desktop, mobile", fuseId:"23041929865"},
    {page:"administrative_area", adPosition:"buy_incontent_3", device:"desktop, mobile", fuseId:"23041929235"},
    {page:"administrative_area", adPosition:"buy_incontent_2", device:"desktop, mobile", fuseId:"23042458286"},
    {page:"administrative_area", adPosition:"buy_incontent_4", device:"mobile", fuseId:"23041929874"},
    {page:"administrative_area", adPosition:"buy_incontent_1", device:"desktop, mobile", fuseId:"23041929868"},

    {page:"advice", adPosition:"advice_incontent_1", device:"desktop, mobile", fuseId:"23041929817"},
    {page:"advice", adPosition:"advice_incontent_2", device:"mobile", fuseId:"23041929820"},
    {page:"advice", adPosition:"advice_incontent_3", device:"mobile", fuseId:"23041929823"},
    {page:"advice", adPosition:"advice_header", device:"desktop, mobile", fuseId:"23041929814"},
    // {page:"advice", adPosition:"advice_vrec_lhs", device:"desktop", fuseId:"23041929826"},
    {page:"advice", adPosition:"advice_incontent_4", device:"mobile", fuseId:"23041929226"},
    // {page:"advice", adPosition:"advice_vrec_rhs", device:"desktop", fuseId:"23041929829"},

    {page:"blog", adPosition:"blog_incontent_1", device:"desktop, mobile", fuseId:"23041929850"},
    {page:"blog", adPosition:"blog_header", device:"desktop, mobile", fuseId:"23041929847"},
    {page:"blog", adPosition:"blog_incontent_2", device:"desktop, mobile", fuseId:"23041929853"},
    // {page:"blog", adPosition:"blog_vrec_lhs", device:"desktop", fuseId:"23041929859"},
    {page:"blog", adPosition:"blog_incontent_3", device:"mobile", fuseId:"23041929856"},
    {page:"blog", adPosition:"blog_incontent_4", device:"mobile", fuseId:"23041929232"},
    // {page:"blog", adPosition:"blog_vrec_rhs", device:"desktop", fuseId:"23041929862"},

    {page:"area_guide", adPosition:"area_incontent_2", device:"mobile, mobile", fuseId:"23042458280"},
    {page:"area_guide", adPosition:"area_incontent_1", device:"desktop, mobile", fuseId:"23041929838"},
    {page:"area_guide", adPosition:"area_header", device:"desktop, mobile", fuseId:"23041929832"},
    // {page:"area_guide", adPosition:"area_vrec_lhs", device:"desktop", fuseId:"23041929229"},
    // {page:"area_guide", adPosition:"area_vrec_rhs", device:"desktop", fuseId:"23041929844"}



];