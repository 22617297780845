<div class="results-header">

    <div class="result-counter"> 
        <div class="result-count">
            <span *ngIf="currentPage>1">
                {{firstPageNumber}} - {{lastPageNumber}} of {{exactMatchCount + extendedMatchCount}}
            </span>
            <span *ngIf="currentPage==1">{{exactMatchCount + extendedMatchCount}}</span>
            <span> results</span>
        </div>   
        
    </div>

    <div class="mode-switcher">
        <pb-dropdown id="sort-by" [borderless]="true" classes="field" caption="Sort by" [options]="sortData" [(selectedValue)]="sortName" (selectedValueChange)="onSortChanged()"></pb-dropdown>
        <pb-button buttonSize="regular" id="list-view" *ngIf="listingMode=='map'" caption="List" iconColour="#4a4a4a" i18n-caption="List|List" buttonFill="text"  useRouterLink="false" actionTrackingLabel="List" leftIcon="list" (clicked)="SelectListingMode('list')"></pb-button>
        <pb-button buttonSize="regular" id="map-view" *ngIf="listingMode=='list'" caption="Map" iconColour="#4a4a4a" i18n-caption="Map|Map" buttonFill="text"  useRouterLink="false" actionTrackingLabel="Map" leftIcon="locationonmap" (clicked)="SelectListingMode('map')"></pb-button>
    </div>
</div>
