<div class="contact-agent property-details-pane">
    <div *ngIf="!completed" class="contact-agent-content">
        <div *ngIf="mode==='contact_agent'" class="header">
            <h1 class="small">Contact agent</h1>
            <p>Please fill out your <b>details</b> and send</p>
        </div>
        <div *ngIf="mode==='request_details_step1' || mode==='request_details_step2'" class="header">
            <h1 class="small">Request details</h1>
            <p>What would you like to&nbsp;<b>know</b>?</p>
        </div>
       
        <a *ngIf="this.propertyDetails" [routerLink]="this.propertyDetails.detailsUrl" rel="nofollow">
            <div class="ca-property-details">
                <div class="left">
                    <img [src]="this.photoUrl"/>
                </div>
                <div class="right">
                    <span class="subtitle3">{{this.propertyDetails.title}}</span>
                    <span class="subtitle3">{{this.propertyDetails.price}}</span>
                    <span class="address">{{this.propertyDetails.address}}</span>
                    <span class="marketed-by">Marketed by {{this.propertyDetails.agentName}}</span>
                </div>    
            </div>
        </a>

        <section *ngIf="propertyDetails.showEnquiryForm && (mode==='request_details_step1' || mode==='request_details_step2')" class="request-details">
            <ul>
                <li *ngFor="let requestDetails of requestDetailsList; let i = index">
                    <check-box [(isSelected)]="requestDetailsSelections[i]" [caption]="requestDetails.value"></check-box>
                </li>
            </ul>
            <pb-button *ngIf="mode==='request_details_step1'" caption="Continue" (clicked)="OnClickedContinueRequestDetails()"></pb-button>
        </section>
        
        <section class="common-form" *ngIf="propertyDetails.showEnquiryForm && mode==='contact_agent' || mode==='arrange_viewing_step2' || mode==='request_details_step2'">
            <section>
                <validating-control-container [validationErrors]="validationErrors" namespace="contactAgent.Name">
                    <pb-textbox placeholder="Full name" caption="Name" [(binding)]="name"></pb-textbox>        
                </validating-control-container>
                <validating-control-container [validationErrors]="validationErrors" namespace="contactAgent.Email">
                    <pb-textbox placeholder="Email" caption="Email" [(binding)]="email"></pb-textbox>        
                </validating-control-container>
                <validating-control-container [validationErrors]="validationErrors" namespace="contactAgent.Telephone">
                    <pb-textbox placeholder="Telephone" caption="Telephone" [(binding)]="telephone"></pb-textbox>        
                </validating-control-container>
                <validating-control-container *ngIf="isForSale" [validationErrors]="validationErrors" namespace="contactAgent.Postcode">
                    <pb-textbox placeholder="Postcode" caption="Postcode" [(binding)]="postcode"></pb-textbox>
                </validating-control-container>
                <validating-control-container [validationErrors]="validationErrors" namespace="contactAgent.Message">
                    <pb-textbox multiLine="true" placeholder="Message" caption="Message" [(binding)]="message"></pb-textbox>
                </validating-control-container>
            </section>
            <section class="share-details"
                *ngIf="propertyDetails.hasShareMyDetailsWithPartner">
                <div class="checkbox-container clear-fix-after">
                    <p (click)="SetCheckBoxOnClick('wantsShareDetails')">
                    {{propertyDetails.shareMyDetailsWithPartnerText}}</p>
                </div>
            </section>
        
            <section class="submit-buttons">
                <pb-button caption="Send message" i18n-caption="Send_Message|Send message button"  useRouterLink="true" actionTrackingLabel="Contact Agent" rel="nofollow" (clicked)="OnClickedSendMessage()"></pb-button>
            </section>

        </section>
    </div>
    <div *ngIf="completed" class="success-content">
        <section>
            <div class="emailVector"></div>
            <section class="header">
                <h1 *ngIf="mode==='contact_agent'">Your contact request has been sent</h1>
                <h1 *ngIf="mode==='request_details_step2'">Your request for more details has been sent</h1>
                <h1 *ngIf="mode==='arrange_viewing_step2'">Your viewing request has been sent</h1>
            </section>
            <section>
                <h1>We sent your request to <b>{{propertyDetails.agentName}}</b>&#46;</h1>
                <label *ngIf="mode==='request_details_step2'">They will get in touch to give you more details about the property.</label>
                <label *ngIf="mode==='arrange_viewing_step2'">They will get in touch to let you know once the viewing is all setup.</label>
                <label>If you do not hear back, we recommend you give them a call on&nbsp;<b>{{propertyDetails.agentPhoneNumber}}</b></label>
            </section>
            <section>
                <div class="uk-credit-report" *ngIf="completed">
                    <h3 class="small">Check & Build Your Credit Report Now</h3>
                    <div class="credit-content">
                        <div class="credito-rocket"></div>
                        <span *ngIf="isForSale" class="credit-text">
                            Prepare for a chat with your estate agent and mortgage advisor by understanding your credit score and how to improve it.
                            <br/>
                            <br/>
                            We've partnered with Credito, the UK's highest rated credit improvement service to get your mortgage ready!
                            <br/>
                            <br/>
                            A great credit score could increase your chance of being accepted for a mortgage or rental agreement.
                        </span>
    
                        <span *ngIf="!isForSale" class="credit-text">
                            Prepare for your chat with your estate agent and landlord by by understanding your credit score and how to improve it.
                            <br/>
                            <br/>
                            We've partnered with Credito, the UK's highest rated credit improvement service to get your score ready!
                            <br/>
                            <br/>
                            A great credit score could increase your chance of being accepted for a mortgage or rental agreement.
                        </span>
                    </div>
                    
                    <a class="cta-credit" target="_blank" rel="nofollow" href="https://www.credito.co.uk/partners?campaign_code=cFxXbgMCBAY&utm_source=placebuzz&utm_medium=affiliate&utm_campaign=placebuzz_thank_you">Check and build your credit score</a>
                </div>
            </section>
            

        </section>
    </div>

    <div *ngIf="completed && existingUserMessage" class="existing-user">
        <label>{{existingUserMessage}}&nbsp;</label>
        <label><b>{{existingUserEmailOrPhone}}</b></label>
        <div class="buttons clear-fix-after">
            <pb-button caption="Log in" (clicked)="OnClickedLogin()"></pb-button>
            <span class="sepsection">or</span>
            <pb-button caption="Email me a Magic Link" (clicked)="OnClickedSendMagicLink()"></pb-button>
        </div>
        <p>This is so we can save your enquiry to your account and so you can send more enquiries without having to fill out your details every time</p>
    </div>

    <div *ngIf="completed" intersection-observer [observeFirstChildElement]="false" observeMargin="200px" [onObservedCallback]="OnSimilarListingsObservedAction">
        <section *ngIf="propertyDetails && propertyDetails.similarListings && propertyDetails.similarListings.length>0" class="properties-nearby-container full-width-auto white-mobile">
            <div class="container-y-pad">
                <div class="header-and-more clear-fix-after">
                    <h2 class="section-title content-mobile-x-pad">
                        Properties on the market nearby
                    </h2>
                </div>
                <div class="nearby-slider-container content-mobile-x-pad">
                    <property-carousel [properties]="propertyDetails.similarListings" imageSizes="small" navigation="true" slidesPerView="4.5" [spaceBetween]="12"></property-carousel>
                </div>
                <div class="more-properties non-mobile-collapse content-mobile-x-pad">
                    
                    <a nav-anchor [href]="propertyDetails.similarPropertiesSearchUrl" action-tracking action-tracking-values="Link,Click,See more similar properties nearby">
                        <span>See similar properties nearby</span>
                        <pb-icon size="18" name="chevronright"/>
                    </a>
                </div>
            </div>
        </section>
    </div>


    
</div>
