import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'image-carousel',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="carousel">
          <div *ngFor="let image of images | slice:0:4" class="carousel-item">
            <img [src]="image.replace('/photo/','/c170x115/photo/')" alt="">
          </div>
          <div *ngIf="images.length>4" class="carousel-item">
            <div class="last-item">
              <span class="carousel-item-count">+{{images.length - 4}}</span>
            </div>
          </div>
    </div>
  `,
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent {
  @Input() images: string[];
  @Input() fullScreenUrl: string;

  ngOnInit() {

  }

  

}
