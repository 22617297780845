<div class="confirm-email-banner" [style.display]="visible===true?'block':'none'">
    <button class="close-button" (click)="OnClickedX()">
        <pb-icon color="#ffffff" name="cross"  size="24px"></pb-icon>
    </button>
    <p>{{text1}}</p>
    <p>
        <label>If you haven't received a confirmation email:</label>
        <span>
            <button (click)="OnClickedResendEmail()" action-tracking action-tracking-values="Button,Click,Confirm Email Banner-Resend">Resend email</button>
            <button (click)="OnClickedChangeAddress()" action-tracking action-tracking-values="Button,Click,Confirm Email Banner-Change">Change email address</button>
        </span>
    </p>
</div>