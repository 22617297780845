import { Inject, Injectable } from '@angular/core';

import { CopyObject } from 'src/app/infrastructure/object.helpers';
import { LoggingService } from '../logging.module/logging.service';
import { CachedModel } from './model';

@Injectable({
  providedIn: 'root'
})


export class ModelCachingService {

  constructor(
    private loggingService: LoggingService,
  ) { }

  private cachedModels: CachedModel[]=[];

  saveModel(url:string, model:any, mode?:string) {

    let existingModel = this.cachedModels.find(x=>x.url == url);
    
    if (existingModel) {
      
      existingModel.model = CopyObject(model);
      this.loggingService.LogToDevConsole("Updating Cache for " + url);
      return;
    }

    this.loggingService.LogToDevConsole("Caching model for " + url);

    this.cachedModels.push ({
      url:url,
      model:CopyObject(model),
      mode:mode
    }); 
  
  }

  getModel(url:string):any {
    
    if (this.cachedModels) {
      let mymodel = this.cachedModels.find(x=>x.url == url);
      if (mymodel) {
        this.loggingService.LogToDevConsole("Returning model from Cache for " + url);
        return CopyObject(mymodel);
      }
      return null;
    }
    return null;
  }

}