import { GqlQueryArgument, GqlQueryPart } from "src/app/services/graphql.module/model";
import { SponsoredListingsQuery } from "./sponsored.listings.query";
import { CreateGqlQueryArguments } from "../create.query";

export const SponsoredListingsByListingIdQuery = `
  sponsoredListings(listingId: $listingId) {
    eCommerceTracking{
			id,
			name,
			brand,
			dimension1,
			category,
			coupon,
			variant,
			price,
      event,
      affiliation
		},
		formattedPrice,
		title,
		subTitle,
		address,
		price,
		weeklyPrice,
		detailsUrl,
		photosTotal,
		marketingInfo,
		firstPublishedDate,
		isNew,
		priceModifier,
		photos,
		features{
			title,
			description,
      iconUrl,
      badgeCount
    },
    campaignId,
    placeId
  }
`;
export const SponsoredListingsByListingIdQueryArgs: Array<GqlQueryArgument> = [
  { variableName: "$listingId", variableType: "String" }
];
export function SponsoredListingsByListingIdQueryPart(listingId: number): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: SponsoredListingsQuery,
    arguments: CreateGqlQueryArguments(SponsoredListingsByListingIdQueryArgs, [listingId])
  };
  return result;
};