import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MortgageEstimate } from 'src/app/infrastructure/model/mortgageEstimate';
import { GetMortgageEstimateMutation } from "src/app/graphql/mutations/GetMortgageEstimateMutation";
import { CopyObject, DeleteProperties, NullableToString } from "src/app/infrastructure/object.helpers";
import { BROWSER_STORAGE_KEYS } from 'src/app/infrastructure/storage.keys';
import { KeyValuePair } from 'src/app/infrastructure/types';
import { HorizontalLoaderComponent } from '../../../components/horizontal-loader/horizontal-loader.component';
import { LinkbuttonComponent } from '../../../controls/link-button/linkbutton.component';
import { PbDropdownComponent } from '../../../controls/pb-dropdown/pb-dropdown.component';
import { PbTextboxComponent } from '../../../controls/pb-textbox/pb-textbox.component';
import { NgIf } from '@angular/common';
import { BrowserStorageService } from 'src/app/services/browser.storage.module/browser.storage.service';
import { GraphQLService } from 'src/app/services/graphql.module/graphql.service';

@Component({
    selector: 'mortgage-calculator',
    templateUrl: './mortgage-calculator.component.html',
    styleUrls: ['./mortgage-calculator.component.scss'],
    standalone: true,
    imports: [NgIf, PbTextboxComponent, PbDropdownComponent, LinkbuttonComponent, HorizontalLoaderComponent]
})

export class MortgageCalculatorComponent {

  constructor(
    private browserStorageService: BrowserStorageService,
    private gqlService: GraphQLService,
    private cdr: ChangeDetectorRef
  ) { }

  _mortgageEstimate: MortgageEstimate;

  @Input() set mortgageEstimate(value: MortgageEstimate) {
    this._mortgageEstimate = value;
    this.mortgageDeposit = NullableToString(value.deposit.toString());
    this.mortgageInterest = NullableToString(value.annualInterest.toString());
    this.mortgagePrice = NullableToString(value.propertyPrice.toString());
    this.mortgageRepaymentPeriod = value.repaymentPeriod;
    if (value.repaymentPeriods){
      this.mortgageRepaymentPeriods = value.repaymentPeriods;
    }
    this.isEstimatingMortgage = false;
  }

  get mortgageEstimate(): MortgageEstimate {
    return this._mortgageEstimate;
  }
  

  mortgageRepaymentPeriods: Array<KeyValuePair<number, string>>;

  isEstimatingMortgage: boolean = false;

  mortgagePrice: string;
  mortgageDeposit: string;
  mortgageInterest: string;
  mortgageRepaymentPeriod: number;


  private SetToZeroIfEmptyString(value: string): string {
    if (!value || value.length === 0) { value = "0"; }
    return value;
  }

  PerformGetMortgageEstimateMutation() {
    
    var mortgageEstimateInput = {
        propertyPrice: this.SetToZeroIfEmptyString(this.mortgagePrice),
        deposit: this.SetToZeroIfEmptyString(this.mortgageDeposit),
        annualInterest: this.SetToZeroIfEmptyString(this.mortgageInterest),
        repaymentPeriod: this.mortgageRepaymentPeriod
    }

    this.isEstimatingMortgage = true;

    this.gqlService.ExecuteMutation(GetMortgageEstimateMutation, mortgageEstimateInput, this.PerformGetMortgageEstimateMutation, this, false).then(response => {
        var result: any = {};
        if (this.gqlService.ProcessResponse(result, response)) {
            var isAboveThreshold = this.mortgageEstimate.isAboveVisibilityThreshold;
            var mortgageEstimate = result.mortgageEstimate as MortgageEstimate;
            mortgageEstimate.isAboveVisibilityThreshold = isAboveThreshold;
            this.mortgageEstimate = mortgageEstimate;
            var mortgageEstimateToSave = CopyObject(mortgageEstimate);
            DeleteProperties(mortgageEstimateToSave, ["isAboveVisibilityThreshold"]);
            this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.MORTGAGE_CALCULATOR, mortgageEstimateToSave);
        }
        this.isEstimatingMortgage = false;
        this.cdr.detectChanges();
    });
}


}
