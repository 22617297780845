import { Component, Input } from '@angular/core';
import { KeyValuePair } from 'src/app/infrastructure/types';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class BreadcrumbComponent {

  @Input() linkList: KeyValuePair<string, string>[];

}
