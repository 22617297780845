import { Component, ViewEncapsulation } from '@angular/core';
import { ISubscriber } from 'src/app/services/messenger.module/model/model';
import { MESSAGE_TYPES } from 'src/app/infrastructure/message.types';
import { RedirectData, TrueFalseMessageData } from 'src/app/infrastructure/message.data.types';
import { IDynamicComponentParameters } from '../../component.base.classes/dynamic.component.container.component.base';
import { NgStyle, NgIf } from '@angular/common';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { FourOFourContainer } from '../four-o-four/four-o-four.container';
import { SecondaryOverlayComponent } from '../secondary-overlay/secondary-overlay.component';
import { Messenger } from 'src/app/services/messenger.module/messenger';
import { AuthorizationService } from 'src/app/services/authorization.module/authorization.service';
import { StatusCodeService } from 'src/app/services/status.code.module/status.code.service';



@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    styleUrls: ['./app-main.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgStyle, RouterOutlet, NgIf, FourOFourContainer, SecondaryOverlayComponent]
})
export class AppMainComponent {
    fixAtScrollTopPixels: number;
    fixedHeaderDynamicComponentParameters: IDynamicComponentParameters;
    // @ViewChild("fourOFour") fourOFourContainer: FourOFourContainer;

    private _isFour0Four: boolean = false;

    set isFour0Four(value: boolean) {
        if (value === false) {
            // if (this.fourOFourContainer) {
            //     this.fourOFourContainer.ngOnDestroy();
            // }
        }
        this._isFour0Four = value;
    }
    get isFour0Four(): boolean {
        return this._isFour0Four;
    }

    private four0fourSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.FOUR_0_FOUR,
        On: (data: TrueFalseMessageData) => {
            this.isFour0Four = data.isTrue
        }
    }

    private redirectSubscriber:ISubscriber = {
        messageType: MESSAGE_TYPES.REDIRECT,
        On: (data: RedirectData) => {
            this.statusCodeService.SetRedirectUrl(data.url);
        }
    }

    constructor(
        private messenger: Messenger,
        private statusCodeService: StatusCodeService
    ) {
        this.messenger.Subscribe(this.four0fourSubscriber);
        this.messenger.Subscribe(this.redirectSubscriber);
    }

    ngOnInit() {
        // this.authorizationService.InvokeExecuteLoggedInStateChangedHook();
    }

    ngOnDestroy() {
        this.messenger.Unsubscribe(this.four0fourSubscriber);
        this.messenger.Unsubscribe(this.redirectSubscriber);
    }
}

