
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ISubscriber } from 'src/app/services/messenger.module/model/model';
import { NavigatedMessageData } from 'src/app/infrastructure/message.data.types';
import { MESSAGE_TYPES } from 'src/app/infrastructure/message.types';
import { Location, NgIf } from '@angular/common';
import { ApplicationStateService } from 'src/app/services/application.state.module/application.state.service';
import { HistoryService } from 'src/app/services/history.service.module/history.service';
import { Messenger } from 'src/app/services/messenger.module/messenger';
import { PbIconComponent } from 'src/app/controls/pb-icon/pb-icon.component';


@Component({
    selector: 'mobile-app-back-button',
    templateUrl: './mobile-app-back-button.component.html',
    styleUrls: ['./mobile-app-back-button.component.scss'],
    standalone: true,
    imports: [NgIf, PbIconComponent]
})

export class MobileAppBackButtonComponent {
    

    constructor(
        private applicationStateService: ApplicationStateService,
        private messenger: Messenger,
        private location: Location,
        private historyService: HistoryService
    ) {
        
    }
    

    OnClicked() {
        this.location.back();
    }
}


