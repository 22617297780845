
<div *ngIf="propertyDetails && propertyDetails.isRedirectToPartner">
    <section class="property-details-redirected">
        <span i18n="Redirection_To_Message|Tells the user they are being redirected to a partner website">Please wait while you are being redirected to a </span>
        <a [href]="propertyDetails.partnerRedirectUrl | safeUrl" target="_blank" i18n="Partner_Website|A partner website we are redirecting to">partner website</a>
    </section>
</div>

<article class="no-longer-avail" *ngIf="propertyDetails && !propertyDetails.isPublished && !propertyDetails.isRedirectToPartner">
    <h6 i18n="Property_No_longer_Available|This property is no longer available">This property is no longer available</h6>
</article>


<div *ngIf="propertyDetails" class="property-details-container">
    <article class="property-header" *ngIf="propertyDetails && !propertyDetails.isRedirectToPartner">
        <div class="left">
            <link-button title="Back" size="medium" i18n-title="Back|Back" beforeIcon="back" (click)="OnClickedBack()"></link-button>
        </div>
        <div class="right">
            <social-sharing class="social-sharing" #socialSharingMobile></social-sharing>
            <favourite-switch class="favourite-button" [placeId]="propertyDetails.listingId" [isSelected]="isFavourite" (requestSelectedChange)="IsFavouriteClicked($event)"></favourite-switch>
        </div>
    </article>

    <div *ngIf="!propertyDetails.isRedirectToPartner" class="property-details-page">

        <div class="property-details-pane">
            <router-outlet></router-outlet>
        </div>
    
        <div *ngIf="this.isDesktopHd" class="ad-pane">
            <div class="side-sticky">
                <ad-unit [showOnMobbile]="false" page="property_details" adPosition="property_vrec_1"></ad-unit>
            </div>
            
            <!-- <dcp-ad-v2 *ngIf="showAds" [doubleClickAd]="this.getAd('property_details', 'side_banner')" [showTestAd]="showTestAds"></dcp-ad-v2> -->
        </div>

    </div>

    <div *ngIf="this.showStickyBanner" class="sticky-bottom-banner">
        <ad-unit page="property_details" adPosition="sticky_banner"></ad-unit>
    </div>
</div>

