import { Component, OnInit, NgModule } from '@angular/core';

@Component({
    selector: 'horizontal-loader',
    templateUrl: './horizontal-loader.component.html',
    styleUrls: ['./horizontal-loader.component.scss'],
    standalone: true
})

export class HorizontalLoaderComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}


