import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'circle-button',
    templateUrl: './circle-button.component.html',
    styleUrls: ['./circle-button.component.scss'],
    standalone: true
})
export class CircleButtonComponent implements OnInit {
    @Input() initial: string;
    @Input() actionTrackingLabel: string;

    constructor() { }

    ngOnInit() { }
}


