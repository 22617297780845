import { Directive, ElementRef, Input, AfterViewInit, OnDestroy } from "@angular/core";
import { ActionAndContext } from "src/app/infrastructure/actionAndContext";
import { LoggingService } from "src/app/services/logging.module/logging.service";
import { PlatformHelpersService } from "../services/platform.helpers.module/platform.helpers.service";

@Directive({
    selector: '[intersection-observer]',
    standalone: true,
})
export class IntersectionObserverDirective implements AfterViewInit, OnDestroy {
    private observer: any;

    @Input() onSetupObserverCallback: ActionAndContext<void>;
    @Input() onObservedCallback: ActionAndContext<void>;
    @Input() observeFirstChildElement: boolean;
    @Input() observeMargin: string;

    private get elementToObserve(): any {
        if (this.elementRef) {
            return this.observeFirstChildElement ? this.elementRef.nativeElement.children[0] : this.elementRef.nativeElement;
        }
    }

    constructor(
        private elementRef: ElementRef,
        private platformService: PlatformHelpersService,
       private loggingService: LoggingService
    ) {

    }

    ngAfterViewInit(): void {
        if (this.platformService.IsBrowserPlatform) {
            setTimeout(()=>{
                this.SetupObserver();
            });
        }
    }

    SetupObserver() {

        if (this.observer) { this.DetachObserverFromImage(); }
        if (this.platformService.IsBrowserPlatform) {
            if (this.elementRef.nativeElement.firstChild && this.elementRef.nativeElement.firstChild.id && this.elementRef.nativeElement.firstChild.id.indexOf('div-gpt')>=0){
                this.loggingService.LogToDevConsole("OBSERVER SET -> " + this.elementRef.nativeElement.firstChild.id);
              }

            if ('IntersectionObserver' in window) {
                if (this.onSetupObserverCallback) {
                    this.onSetupObserverCallback.Execute();
                }
                let config = { root: null, rootMargin: this.observeMargin || '0px', threshold: 0.5 };
                this.observer = new IntersectionObserver(this.OnObserverChanges.bind(this), config);
                this.observer.observe(this.elementToObserve);
            }
        }
    }



    DetachObserverFromImage() {
        if (this.observer) {
            try {
                if (this.elementRef.nativeElement.firstChild && this.elementRef.nativeElement.firstChild.id && this.elementRef.nativeElement.firstChild.id.indexOf('div-gpt')>=0){
                  this.loggingService.LogToDevConsole("OBSERVER detached -> " + this.elementRef.nativeElement.firstChild.id);
                  }
                this.observer.unobserve(this.elementToObserve);
            }
            catch{ }
        }
    }

    private OnObserverChanges(changes, observer) {
        for (let change of changes) {
            if (change.intersectionRatio > 0) {
                if (this.observer) {
                    try {
                        if (this.onObservedCallback) {
                            this.onObservedCallback.Execute();
                        }
                        this.observer.unobserve(change.target);
                        this.observer = null;
                    }
                    catch{ }
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.DetachObserverFromImage();
      }

    public resetObserver(): void {
        this.DetachObserverFromImage();
        this.SetupObserver();
      }
}
