<div class="sr-header">
    <div class="title-container">
        <h1 class="small">{{title}}</h1>
        
        <div class="alert-buttons">
            <pb-button *ngIf="!hasCriteriaChanged" extraClasses="save-search" buttonStyle="primary"  buttonSize="small" iconColour="#4a4a4a" [caption]="isSearchSaved?'Search saved':'Save search'" leftIcon="heart" [isDisabled]="isSearchSaved" (clicked)="onSaveClicked()"></pb-button>
            <pb-button *ngIf="hasCriteriaChanged" extraClasses="save-search" buttonStyle="primary"  buttonSize="small" iconColour="#4a4a4a" caption="Update my search" leftIcon="heart" (clicked)="onSaveClicked()"></pb-button>
            <pb-button *ngIf="!isAlertOn" extraClasses="create-alert" buttonStyle="primary" buttonFill="text" buttonSize="medium" iconColour="#4a4a4a" [caption]="isSearchSaved?'Turn alerts on':'Create alert'" leftIcon="bell" (clicked)="onAlertsClicked()"></pb-button>
        </div>
    </div>

    <div class="sharing-container">
        <social-sharing
          [shareUrl]="shareUrl"
          [shareSubjectText]="metaTitle"
          [shareContentText]="metaDescription"
          [shareSocialMediaText]="metaDescription">
        </social-sharing>
    </div>
</div>
<div class="ratings">
    <a *ngIf="areaGuide" class="rating-container" [href]="areaGuide.relativePath" nav-anchor [attr.rel]="areaGuide.linkRel||null">
            <rating-stars [rating]="areaGuide.averageScore" [ratingText]="areaGuide.averageScore.toFixed(1)"></rating-stars>
            <div class="link">Rate this area</div>
    </a>
</div>
<h2 *ngIf="subTitle">{{subTitle}}</h2> 