<icon-button icon="user" [style.display]="isUserLoggedIn?'none':'inline'" iconClasses="nav-bar-icon-button nav-bar-icon-button-last" (click)="OnClick($event)" actionTrackingLabel="Profile"></icon-button>
<circle-button [style.display]="isUserLoggedIn?'inline':'none'" initial="{{initial}}" (click)="OnClick($event)" actionTrackingLabel="Profile"></circle-button>

<div *ngIf="isPopupVisible" class="account-menu-container">
    <div class="account-menu">
        <div>
            <a *ngIf="!isUserLoggedIn" (click)="ClickedSignup()" action-tracking action-tracking-values="Link,Click,Profile-Sign Up">Sign up</a>
            <a *ngIf="isUserLoggedIn" routerLink="/account" action-tracking action-tracking-values="Link,Click,Profile-My Account">My account</a>
            <p></p>
            <a *ngIf="!isUserLoggedIn" (click)="ClickedLogin()" action-tracking action-tracking-values="Link,Click,Profile-Log In">Log in</a>
            <a *ngIf="isUserLoggedIn" (click)="ClickedLogout()" action-tracking action-tracking-values="Link,Click,Profile-Log Out">Logout</a>
        </div>
    </div>
</div>