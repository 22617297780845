<div class="swiper-container">
    <swiper #swiper [config]="this.config">
        <ng-template *ngFor="let property of properties" swiperSlide>
            <a *ngIf="property" [href]="property.detailsUrl|safeUrl" nav-anchor class="property-nearby" rel="{{isExternalLink?'nofollow':'follow'}}" [server-side]="isExternalLink" [new-tab]="isMobileApp">
                <div class="property-container">
                    <picture class="slider-photo">
                        <source [srcset]="getWebpUrl(property.photos[0])" type="image/webp"/>
                        <source [srcset]="getJpgUrl(property.photos[0])" type="image/jpeg" />
                        <img class="slider-image" [src]="getImageUrl(property.photos[0])" width="100%" height="auto" loading="lazy" class="swiper-lazy"/>
                    </picture>
                </div>
                <div class="carousel-prop-details">
                    <span class="property-price subtitle3">{{property.price}}</span>
                    <!-- <div class="subtitle2">{{property.subTitle}}</div> -->
                    <div class="body2">{{property.address}}</div>
                </div>
            </a>
            <div class="swiper-lazy-preloader"></div>
        </ng-template>
    </swiper>
</div>
