import { Component, OnInit, EventEmitter, NgModule } from "@angular/core";
import { ErrorMessageData } from "src/app/infrastructure/message.data.types";
import { ERROR_MESSAGE_TYPES } from "src/app/services/graphql.module/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { PopupContainedComponent } from "src/app/controls/popup/popup.component";
import { CommonModule, NgIf } from "@angular/common";
import { PbButtonComponent } from "../../../controls/pb-button/pb-button.component";

import { Messenger } from "src/app/services/messenger.module/messenger";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";

@Component({
    selector: 'error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: true,
    imports: [NgIf, PbButtonComponent]
})
export class ErrorComponent implements PopupContainedComponent, OnInit {
    canUserClose: boolean = false;
    opacity: number = 0;
    popupWidth: string = "636px"
    popupHeight: string = "440px";
    mobilePopupWidth: string = "100%";
    mobilePopupHeight: string = "440px";
    SizesChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    shouldStartAnimating: boolean;
    isInPopup: boolean = true;
    parameters: any;
    isNoFixedHeight: boolean = false;

    heading: string;
    subHeading: string;
    hasButton: boolean;
    buttonCaption: string;

    actionTrackingLabel: string;

    private get error() {
        return this.parameters as ErrorMessageData;
    }

    constructor(
        
        private messenger: Messenger,
        private platformService: PlatformHelpersService
    ) { }

    ngOnInit(): void {
        switch (this.error.errorType) {
            case ERROR_MESSAGE_TYPES.Connection_Timed_Out:
                this.actionTrackingLabel = "Error-Retry"
                this.heading = "Connection Timed Out";
                this.subHeading = "Please check your internet connection and try again";
                this.buttonCaption = "Try again";
                this.hasButton = true;
                break;
            case ERROR_MESSAGE_TYPES.Something_Went_Wrong:
                this.actionTrackingLabel = "Error-Go to home page"
                this.heading = "Oops! Something went wrong!";
                this.subHeading = "Our team has been alerted and we're on it! Please click try again to refresh your browser";
                this.hasButton = true;
                this.buttonCaption = "Try again";
                break;
        }
    }

    OnRetryClicked() {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.ERROR,
            messageData: new ErrorMessageData(null, null, null)
        });
        switch (this.error.errorType) {
            case ERROR_MESSAGE_TYPES.Connection_Timed_Out:
                if (this.error.retryAction) {
                    if (this.error.retryContext) {
                        this.error.retryAction.call(this.error.retryContext);
                    }
                    else {
                        this.error.retryAction();
                    }
                }
                else {
                    if (this.platformService.IsBrowserPlatform) {
                        this.platformService.Window.location.reload();
                    }
                }
                break;
            case ERROR_MESSAGE_TYPES.Something_Went_Wrong:
                this.platformService.Window.location.reload();
                break;
        }
    }
}

