import { Component} from "@angular/core";
import { IconButtonComponent } from "src/app/modules/isolated.components/icon-button/icon-button.component";
import { AccountButtonComponent } from "../../account-button/account-button.component";
import { IconButtonComponent as IconButtonComponent_1 } from "../../../modules/isolated.components/icon-button/icon-button.component";


@Component({
    selector: 'nav-bar-icon-buttons',
    templateUrl: './nav-bar-icon-buttons.component.html',
    styleUrls: ['./nav-bar-icon-buttons.component.scss'],
    standalone: true,
    imports: [IconButtonComponent_1, AccountButtonComponent]
})
export class NavBarIconButtonsComponent {
    iconButtonsList: Array<IconButtonComponent>;

    constructor() { }
}

