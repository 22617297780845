import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakpointService } from 'src/app/services/breakpoint.module/breakpoint.service';
import { AdsService } from 'src/app/services/ads.service.module/ads.service';
import { ApplicationStateService } from 'src/app/services/application.state.module/application.state.service';
import { PlatformHelpersService } from 'src/app/services/platform.helpers.module/platform.helpers.service';


@Component({
  selector: 'fuse-ad',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fusead.component.html',
  styleUrls: ['./fusead.component.scss'],
  providers: [BreakpointService, AdsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseAdComponent implements AfterViewInit, OnDestroy {
  constructor(
    private adsService: AdsService,    
  ) {
    
  }
  ngOnDestroy(): void {
    this.adsService.destroyZone(this.adPosition);
  }
  ngAfterViewInit(): void {
    this.adsService.registerZone(this.adPosition);
  }

    @Input() adPosition: string;
    

    showTestAd: boolean = false;


    get fuseId(): string {
        return this.adsService.getAdFuseId(this.adPosition);
    }

}
