import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { PropertyDetails } from '../property-details-page/model';
import { RemoveSubscriptions } from 'src/app/infrastructure/object.helpers';
import { Location, NgIf } from '@angular/common';
import { PbButtonComponent } from '../../../controls/pb-button/pb-button.component';
import { PbCarouselComponent } from '../../../controls/pb-carousel/pb-carousel.component';
import { LinkbuttonComponent } from '../../../controls/link-button/linkbutton.component';
import { PropertyDetailsService } from 'src/app/services/property.details.module/property.details.service';
import { AdUnitComponent } from 'src/app/components/ad-unit/ad-unit.component';

@Component({
    selector: 'image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, LinkbuttonComponent, PbCarouselComponent, PbButtonComponent, AdUnitComponent]
})
export class ImageViewerComponent implements OnInit, OnDestroy {
  constructor(private cdr: ChangeDetectorRef,
    private propertyDetailsService: PropertyDetailsService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { 
    
  }
  ngOnDestroy(): void {
    RemoveSubscriptions(this.subscriptions);
  }

  private subscriptions: Array<Subscription> = new Array<Subscription>();

  images: any;
  propertyDetails: PropertyDetails;
  page:string;
  imagesUrl: string[] = [];

  initialSlide:number = 1;
  counterText:string = '';

  ngOnInit(): void {

    this.subscriptions.push(
      combineLatest([
        this.route.paramMap,
        this.propertyDetailsService.getPropertyDetailsObservable()
      ])
      .subscribe(([params, details]) => {
        if (this.page == this.route.snapshot.url[0].path){
          return;
        }
  
        this.propertyDetails = details;
  
        if (params && details) {
          this.updatePageDetails(details);
  
        }
      })
    );
  }

  updatePageDetails(details: PropertyDetails){
    
      this.propertyDetails = details;

      if (this.route.snapshot.url[0]){
        this.page = this.route.snapshot.url[0].path;
  
        if (this.route.snapshot.url[1]){    
          this.initialSlide = parseInt(this.route.snapshot.url[1].path);
        }
        else{
          this.initialSlide = 1;
        }
          switch (this.page){
              case 'images':
                  this.page = "images";
                  this.imagesUrl = details.photos;
                  break;
              case 'floorplans':
                  this.page = "floorplans";
                  this.imagesUrl = details.floorPlans;
                  break;
              case 'documents':
                  this.imagesUrl = this.getDocuments();
                  this.page = "documents";
                  break;
              default:
                  this.page = "property-details";
                  break;
          }    
      }
      
      this.counterText = `${this.initialSlide} / ${this.imagesUrl.length}`;
      this.cdr.detectChanges();
  }
  
  goToPage(page:string){
    this.ngZone.run(() => {
      this.router.navigate([`${this.propertyDetails.detailsUrl}/${page}`], { relativeTo: this.route});
    });
  }

  onSlideChanged(event:any) {
    this.counterText = `${event} / ${this.imagesUrl.length}`;

    const newUrl = `${this.propertyDetails.detailsUrl}/${this.page}/${event}`;
    this.location.replaceState(newUrl);

    this.cdr.detectChanges();
  }

  onClickedContactAgent(){
    this.router.navigate([`/${this.propertyDetails.detailsUrl}/contact-agent`]);
  }

  onClickedRequestDetails(){
    this.router.navigate([this.propertyDetails.detailsUrl + "/contact-agent"], {queryParams: {mode: 'request_details_step1'}});
  }

  getDocuments(){
    let documents = new Array<string>();
    if (this.propertyDetails.documents && this.propertyDetails.documents.length > 0) {
      for (var i = 0; i < this.propertyDetails.documents.length; i++) {
        var split = this.propertyDetails.documents[i].url.split(".");
        if (split.length > 1) {
          var extension = split[split.length - 1].toLowerCase().trim();
          if (extension === "bmp" ||
            extension === "gif" ||
            extension === "jpg" ||
            extension === "jpeg" ||
            extension === "png") {
            documents.push(this.propertyDetails.documents[i].url);
          }
        }
      }
    }
    return documents;
  }
}
