import { Pipe, PipeTransform, Inject } from '@angular/core';
import { AppSettings } from 'src/app/infrastructure/appSettings';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'media',
    standalone: true,
})

export class MediaPipe implements PipeTransform {
    constructor() { }

    transform(value: string): any {
        if (!value) return;
        if (value.startsWith("/")) { value = value.substr(1, value.length - 1); }
        return (environment.mediaServerUrl + value);
    }
}

@Pipe({
    name: 'cdn',
    standalone: true,
})

export class CdnPipe implements PipeTransform {
    constructor() { }

    transform(value: string): any {
        if (!value) return;
        if (value.startsWith("/")) { value = value.substr(1, value.length - 1); }
        return (AppSettings.cdnUrl + value);
    }
}
