import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({providedIn: 'root'})
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('Global Error Handler:', error);
    if (error instanceof Error) {
      Sentry.captureException(error, {extra: {
        message: error.message,
        stack: error.stack,
        url: window.location.href,
        userAgent: navigator.userAgent
      }});
    } else if (typeof error === 'string') {
      Sentry.captureMessage(error);
    } else {
      try {
        Sentry.captureException(JSON.stringify(error));
      } catch {
        Sentry.captureMessage('Global Error Handler: Error in error handler - Error of unknown type');
      }
    }
  }
}