import { Input, Output, EventEmitter, Directive } from '@angular/core';
@Directive()
export abstract class MenuItemComponentBase {
    @Input() IsCurrentSelection: boolean = false;
    @Input() itemId: string;
    @Output() itemClickedEvent = new EventEmitter<string>();

    abstract DoOnItemClicked(): void;

    OnClicked(): void {
        this.itemClickedEvent.emit(this.itemId);
        this.DoOnItemClicked();
    }
}