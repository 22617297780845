
import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, NavigationSkipped, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { NavBarComponent } from './components/layout/nav-bar/nav-bar.component';
import { AppMainComponent } from './modules/isolated.components/app-main/app-main.component';
import { ConfirmEmailBannerComponent } from './modules/isolated.components/confirm-email-banner/confirm-email-banner.component';
import { InAppToastComponent } from './modules/isolated.components/in-app-toast/in-app-toast.component';
import { ErrorOverlayContainerComponent } from './modules/isolated.components/error-overlay-container/error-overlay-container.component';
import { OverlayContainerComponent } from './modules/isolated.components/overlay-container/overlay-container.component';
import { PbFooterComponent } from './components/layout/pb-footer/pb-footer.component';
import { Messenger } from './services/messenger.module/messenger';
import { MESSAGE_TYPES } from './infrastructure/message.types';
import { TrueFalseMessageData } from './infrastructure/message.data.types';
import { NgIf } from '@angular/common';
import { UrlHelpersService } from './services/url.helpers.module/url.helpers.service';
import { AdScriptLoaderService } from './services/ad.script.loader.service/ad.script.loader.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [  
            RouterOutlet, 
            NavBarComponent, 
            AppMainComponent, 
            ConfirmEmailBannerComponent, 
            InAppToastComponent, 
            ErrorOverlayContainerComponent, 
            OverlayContainerComponent,
            PbFooterComponent,
            NgIf
          ],
  templateUrl: './app.component.html',
  host: {'(window:click)': 'OnWindowClick($event)'},
  styleUrl: './app.component.scss'
})

export class AppComponent implements AfterViewInit {

  title = 'placebuzz';
  showFooter: boolean = true;
  showNavBar: boolean = true;
  private shouldPreventDefaultOnClick: boolean = false;

  private OnWindowClick(event) {
    if (this.shouldPreventDefaultOnClick) {
      event.preventDefault();
      this.shouldPreventDefaultOnClick = false;
    }
    this.messenger.Send({
      messageType: MESSAGE_TYPES.APP_CLICKED,
      messageData: {}
    });
  }


constructor(
  private router: Router,
  private messenger: Messenger, 
  private adScriptLoaderService: AdScriptLoaderService,
  private urlHelpersService: UrlHelpersService 
) {

  const noHeaderFooterRoutes = [
    "/members",
    "/members/account-settings",
    "/members/edit-listing",
    "/members/manage-listings",
    "/members/performance",
    "/members/subscription-billing",
    "/members/leads",
    "/members/lead",
    "/members/support",
    "/members/feed-settings"
  ];

  this.router.events.subscribe((event) => {

    if (event instanceof NavigationStart){
      this.messenger.Send({
        messageType: MESSAGE_TYPES.FOUR_0_FOUR,
        messageData: new TrueFalseMessageData(false)
      });
    }

    if (event instanceof NavigationEnd) {
      const urlParts = event.url.split('?');
      const urlPath = urlParts[0];
      
      if (noHeaderFooterRoutes.includes(urlPath)) {
        this.showNavBar = false;
        this.showFooter = false;
      }
      else{
        this.showNavBar = true;
        this.showFooter = true;
      }

      if (urlPath.endsWith("/map")) {
        this.showFooter = false;
      }
      else{
        this.showFooter = true;
      }

      this.closePopUp();
      
    }

    if (event instanceof NavigationSkipped){
      this.closePopUp();
    }



  });

}

  closePopUp(){
    var parsedUrl = this.urlHelpersService.GetParsedUrl(this.urlHelpersService.GetCurrentUrl());
      
      if (!parsedUrl.queryObject.popupvisible) {
        this.messenger.Send({
          messageType: MESSAGE_TYPES.CLOSE_POPUP,
          messageData: {}
        });
      }
  }

  ngAfterViewInit(): void {
    this.adScriptLoaderService.loadScript().catch(err =>{ });
  }

}
