<nav class="nav-bar" [ngClass]="{'menu-open': IsHamburgerOpen }">
    
    <div itemscope itemtype="http://schema.org/Organization">
        <a class="app-logo" routerLink="/"></a>
    </div>
    
    <div class="logo-master">
        <mobile-app-back-button *ngIf="this.isMobileApp && this.hasBackStack"></mobile-app-back-button>
        <div class="logo-button-container {{isMobileApp && this.hasBackStack?'mobile-app':''}}">
            <button class="logo-button"  title="Mobile Menu Button" (click)="OnTapped()" action-tracking action-tracking-values="Icon,Click,Hamburger">
                <span class="logo-hamburger-container">
                    <span class="hamburger-svg" [ngClass]="{'hamburger-svg-open': IsHamburgerOpen}"></span>
                </span>
            </button>
            <div class="logo-button-badge-container">
                <badge badgeCount="{{badgeCount}}" class="logo-button-badge"></badge>
            </div>
        </div>
    </div>
   
    <div class="mobile-logo-container">
        <a class="mobile-logo" routerLink="/"></a>
    </div>

    <div class="nav-bar-responsive">
        <div class="nav-bar-links-container">
            <ul class="nav-bar-links">
                <li *ngFor="let menuItem of Menu.items" class="nav-bar-link-container">
                    <a *ngIf="menuItem.link" class="nav-bar-link" (click)="OnClicked($event)" [href]="menuItem.link|safeUrl" nav-anchor>
                        <span class="nav-bar-link-content-container">
                            <span class="nav-bar-link-text">{{menuItem.text}}</span>
                            <span class="nav-bar-badge-container"><badge class="nav-bar-badge" badgeCount="{{menuItem.badgeCount}}"></badge></span>
                        </span>
                        <span *ngIf="menuItem.isCurrentSelection" class="nav-bar-link-selected-indicator"></span>
                    </a>
                    <a *ngIf="!menuItem.link" href="#" class="nav-bar-link" (click)="OnClicked($event, menuItem)" action-tracking [ngClass]="{'current-selection':menuItem.isCurrentSelection}">
                        <span class="nav-bar-link-content-container">
                            <span class="nav-bar-link-text">{{menuItem.text}}</span>
                            <span class="nav-bar-badge-container"><badge class="nav-bar-badge" badgeCount="{{menuItem.badgeCount}}"></badge></span>
                        </span>
                        <span *ngIf="menuItem.isCurrentSelection" class="nav-bar-link-selected-indicator"></span>
                    </a>
                    <div *ngIf="menuItem.subItems" class="dropdown-menu-bar" [ngClass]="{'sub-menu-visible':menuItem.isCurrentSelection}">
                        <ul class="dropdown-menu-container">
                            <li class="dropdown-item-container" *ngFor="let subItem of menuItem.subItems">
                                <a *ngIf="subItem.type===0" class="dropdown-item" [href]="subItem.link|safeUrl" nav-anchor action-tracking (click)="OnClicked($event)">
                                    <div>
                                        <span *ngIf="!subItem.iconUrl" class="{{subItem.fontIconClass}} {{subItem.iconClasses}} dropdown-font-icon"></span>
                                        <span *ngIf="subItem.iconUrl" fill-svg [svg-url]="subItem.iconUrl"></span>
                                    </div>
                                    <label>{{subItem.caption}}</label>
                                </a>
                                <div *ngIf="subItem.type===1" class="dropdown-separator" [ngClass]="{'hide-mobile':subItem.hideOnMobile}"></div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <nav-bar-icon-buttons class="icon-buttons"></nav-bar-icon-buttons>
</nav>

