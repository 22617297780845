
    <div class="header-content padded-article">
        <h1 *ngIf="this.page=='images'" class="small">Photos</h1>
        <h1 *ngIf="this.page=='floorplans'" class="small">Floorplans</h1>
        <h1 *ngIf="this.page=='documents'" class="small">Documents</h1>
        <span class="h1">&nbsp;({{this.imagesUrl.length}})</span>    
    </div>
    

    <ad-unit page="property_details" adPosition="property_header"></ad-unit>

    <div *ngIf="this.page=='images' || this.page=='floorplans' || this.page=='documents'" class="property-details-pane">
        <article class="prop-details content padded-article">
            <h1 class="small">{{ propertyDetails.title }}</h1>
            <span class="subtitle1">{{ propertyDetails.address }}</span>
            <div class="price">
                <span class="h1 small">{{ propertyDetails.price }}</span>
                <span *ngIf="propertyDetails.priceModifier">
                    {{propertyDetails.priceModifier}}
                </span>
                <div class="reverse-page-links">
                    <link-button *ngIf="this.page!='floorplans' && propertyDetails.floorPlans && propertyDetails.floorPlans.length>0" i18n-title="Floorplan|Floorplan" title="Floorplan" beforeIcon="floorplan" border="true" size="small" (linkClicked)="goToPage('floorplans')"></link-button>
                    <link-button *ngIf="this.page!='images' && propertyDetails.photos && propertyDetails.photos.length>0" i18n-title="Photos|Photos" title="Photos" beforeIcon="photos" border="true" size="small" (linkClicked)="goToPage('images')"></link-button>
                    <link-button *ngIf="this.page!='documents' && propertyDetails.documents && propertyDetails.documents.length>0" i18n-title="Documents|Documents" title="Documents" beforeIcon="document" border="true" size="small" (linkClicked)="goToPage('documents')"></link-button>
                </div> 
            </div>
        </article>
    

        <article>        
            <pb-carousel cropPhotos="false" imageSizes="large" [images]="this.imagesUrl" [initialSlide]="this.initialSlide" navigation="true" slidesPerView="1" (slideChange)="this.onSlideChanged($event)"></pb-carousel>
            <span class="counter">{{counterText}}</span>
    
            <ad-unit page="property_details" adPosition="property_incontent_1"></ad-unit>
    
            <div *ngIf="propertyDetails.showEnquiryForm" class="contact-buttons">
                <pb-button caption="Contact agent" i18n-caption="Contact_Agent|Contact agent button"  useRouterLink="true" actionTrackingLabel="Contact Agent" rel="nofollow" (clicked)="onClickedContactAgent()"></pb-button>
                <pb-button caption="Request more details" i18n-caption="Request_Details|Request more details button" isSecondary="true" useRouterLink="true" rel="nofollow" actionTrackingLabel="Request Details" (clicked)="onClickedRequestDetails()"></pb-button>
            </div>        
        </article>
    </div>






