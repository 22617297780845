<div class="overlay-container">
    <div class="overlay" (click)="OnClickedOverlay($event)" *ngIf="isOverlayVisible===true"></div>
    <!-- <div class="overlay" (click)="OnClickedOverlay($event)" [style.display]="isOverlayVisible===true?'block':'none'"></div> -->
    <div class="dynamic-component-container" [style.display]="isDynamicVisible===true?'block':'none'">
        <div #dynamicComponentContainer></div>
    </div>
    <loader *ngIf="isLoaderVisible===true"></loader>
    <div *ngIf="message" class="loader-message">
        {{message}}
    </div>
</div>