<pb-search-bar [placeSearchCriteria]="placeSearchCriteria" (searchClicked)="onSearchFiltersChanged()"></pb-search-bar>

<div class="page-container full-width {{this.listingMode}}"  #root>
    
    <search-header 
        *ngIf="this.listingMode=='list'"
        [title]="propertyListings?.heading" 
        [subTitle]="propertyListings?.filterText"
        [shareUrl]="placeSearchCriteria?.shareUrl" 
        [areaGuide] = "propertyListings?.areaGuide"
        [metaTitle]="propertyListings?.robots.metaTitle" 
        [metaDescription]="propertyListings?.robots.metaDescription"
        [isSearchSaved]="placeSearchCriteria?.placeSearchCriteriaId>0"
        [isAlertOn]="placeSearchCriteria?.hasAlerts"
        [hasCriteriaChanged]="this.searchCriteriaChanged"
        (saveClicked)="this.OnSaveClicked()"
        (alertsClicked)="this.onAlertsClicked()">
    </search-header>

    <results-header 
        *ngIf="this.listingMode=='list'"
        [currentPage]="propertyListings?.pagination.currentPage" 
        [firstPageNumber]="propertyListings?.pagination.pageFirstItemNumber" 
        [lastPageNumber]="propertyListings?.pagination.pageLastItemNumber" 
        [exactMatchCount]="propertyListings?.exactMatchCount" 
        [extendedMatchCount]="propertyListings?.extendedMatchCount"
        [sortName]="placeSearchCriteria?.sortType"
        (listingModeChanged) = "SelectListingMode($event)"
        (sortChanged)="onSortChanged($event)">
    </results-header>

    <search-list *ngIf="this.listingMode=='list'" [propertyListings]="this.propertyListings" (pageChanged)="onPageChanged($event)" (newPageClicked)="onPageClicked($event)" (saveClicked)="this.OnSaveClicked()" [isSearchSaved]="placeSearchCriteria?.placeSearchCriteriaId>0"></search-list>
    
    <search-map [placeSearchCriteria]="this.placeSearchCriteria" [propertyListings]="this.propertyListings" *ngIf="this.listingMode=='map'" (listClicked)="SelectListingMode('list')" (searchClicked)="OnSearchHereClicked($event)"></search-map>

</div>

<div *ngIf="this.showStickyBanner" class="sticky-bottom-banner">
    <ad-unit page="search_results" adPosition="sticky_banner"></ad-unit>
</div>
