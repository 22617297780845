
export const GetMortgageEstimateMutation = `
    mutation getMortgageEstimate($input: mortgageEstimateInput) {
        mortgageEstimate(mortgageEstimateInput: $input){
            propertyPrice,
            currencySymbol,
            deposit,
            annualInterest,
            repaymentPeriod,
            estimatedMonthlyMortgage
            estimatedMonthlyMortgageInfo
        }
    }
`;
