export const LoadLazy = {
    LoginComponent: async () => {
        return (await import('./modules/lazy.components.modules/login.component.module/login.component')).LoginComponent;
    },
    YesNoPopupComponent: async () => {
        return (await import('./modules/lazy.components.modules/yes-no-popup.component.module/yes-no-popup.component')).YesNoPopupComponent;
    },
    TextPopupComponent: async () => {
        return (await import('./modules/lazy.components.modules/text-popup.component.module/text.popup.component')).TextPopupComponent;
    }
}


