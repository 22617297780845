import { Component, Input, NgModule } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';

@Component({
    selector: 'badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class BadgeComponent {
    private _badgeCount: string = "0";

    @Input() set badgeCount(value: string) {
        this._badgeCount = value;
    }
    get badgeCount(): string {
        if (parseInt(this._badgeCount) > 99) {
            return "99+";
        }
        return this._badgeCount;
    }

    get isBadgeVisible(): boolean {
        return parseInt(this.badgeCount) > 0;
    }
}


