
export const ContactAgentMutation = `
    mutation contactAgent($input: contactAgentInput) {
        contactAgent(contactAgentInput: $input){
            isExistingUser,
            isValid,
            token,
            existingUserMessage,
            existingUserEmailOrTelephone,
            price
            errors{
                message,
                source
            }
        }
    }
`;
