// search.routes.ts
import { Route } from "@angular/router";
import { SearchPageComponent } from "./search-page/search-page.component";
import { SearchMapComponent } from "./search-map/search-map.component";
import { SearchListComponent } from "./search-list/search-list.component";

const searchRoutes: Route[] = [
  {
    path: '',
    component: SearchPageComponent, 
    children: [
      {
        path: 'map',
        component: SearchMapComponent,
        data: { page: 'map', allowClientCredentials: true }
      },
      {
        path: '',
        component: SearchListComponent,
        data: { page: 'list', allowClientCredentials: true }
      },
      {
        path: ':street',
        component: SearchListComponent,
        data: { page: 'list', allowClientCredentials: true }
      },
      {
        path: ':street/map',
        component: SearchMapComponent,
        data: { page: 'map', allowClientCredentials: true }
      }
    ]
  }
];

export default searchRoutes;