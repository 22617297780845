<div *ngIf="doubleClickAd && (doubleClickAd.mode=='sra' && doubleClickAd.adProvider=='admanager')" class="ad-container ad-wrap-container" [ngStyle]="{'min-height': this.minHeightPx}">
  <div [attr.id]="this.doubleClickAd.slotId" [style.width]="widthPx" style="margin:auto;" class="{{extraClasses}}"></div>
</div>

<div *ngIf="doubleClickAd && (doubleClickAd.mode=='lazy' && doubleClickAd.adProvider=='admanager')" class="ad-container ad-wrap-container" [ngStyle]="{'min-height': this.minHeightPx}" intersection-observer [observeFirstChildElement]="false" [observeMargin]="observeMargin" [onObservedCallback]="OnObservedAction">
  <div [attr.id]="this.doubleClickAd.slotId" [style.width]="widthPx" style="margin:auto;" class="{{extraClasses}}" ></div>
</div>

<div *ngIf="doubleClickAd && doubleClickAd.adProvider=='tude'" class="ad-container ad-wrap-container" [ngStyle]="{'min-height': this.minHeightPx}" intersection-observer [observeFirstChildElement]="false" [observeMargin]="observeMargin" [onObservedCallback]="OnObservedAction">
  <div [attr.id]="this.tudeName" [style.width]="widthPx" style="margin:auto;" class="{{extraClasses}}" ></div>
</div>