import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { PbIconComponent } from 'src/app/controls/pb-icon/pb-icon.component';

@Component({
  selector: 'link-button',
  standalone: true,
  imports: [CommonModule, PbIconComponent, RouterModule],
  templateUrl: './linkbutton.component.html',
  styleUrls: ['./linkbutton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkbuttonComponent {
  constructor(
    private router: Router
  ) {

  }
  iconSize:string = "16";
  private _size: string = "small";


  @Input() title: string;
  @Input() href: string;
  @Input() beforeIcon: string;
  @Input() afterIcon:string;

  @Output() linkClicked = new EventEmitter<string>();

  onClick(event) {
    event.preventDefault();
    this.linkClicked.emit(this.href);

    if (this.href == null || this.href == undefined) {
      return;
      }

    if (this.newWindow) {
      window.open(this.href, "_blank");
    } else {
      this.router.navigateByUrl(this.href);
    }

  }

  @Input() set size(value: string) {
    this._size = value;

    switch (value) {
      case "small":
        this.iconSize = "16";
        break;
      case "medium":
        this.iconSize = "20";
        break;
      case "large":
        this.iconSize = "24";
        break;
      case "x-large":
        this.iconSize = "36";
        break;
      default:
        this.iconSize = "16";
        break;
    }
  }

  get size(): string {
    return this._size;
  }

  @Input() vertical:boolean = false;

  @Input() border:boolean = false;

  @Input() fullWidth:boolean = false;

  @Input() nofollow:boolean = false;

  @Input() newWindow:boolean = false;

  @Input() externalLink:boolean = false;

}
