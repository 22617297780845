import { ChangeDetectionStrategy, OnInit, OnDestroy, Component, Input, ChangeDetectorRef, NgModule, ViewEncapsulation } from "@angular/core";
import { PropertyListing } from "src/app/infrastructure/model/propertyListing";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { AdaptiveStateChangedMessageData } from "src/app/infrastructure/message.data.types";
import { ImageSize, KeyValuePair } from "src/app/infrastructure/types";
import { ActionAndContext } from "src/app/infrastructure/actionAndContext";
import { ElapsedTimeDisplay, ShortElapsedTimeDisplayWithTime } from "src/app/infrastructure/date.helpers";
import { EnquiryItem } from "src/app/modules/isolated.components/enquiry.item.component/model";
import { NgIf, NgClass, NgStyle, NgFor } from "@angular/common";
import { PropertyFeaturesComponent } from "../property-features/property-features.component";
import { FavouriteSwitchComponent } from "src/app/controls/favourite-switch/favourite-switch.component";
import { PropertyListingImageComponent } from "../../../components/property-listing-image/property-listing-image.component";

import { SafeUrlPipe } from "../../../pipes/safe.url.pipe";
import { IntersectionObserverDirective } from "../../../directives/interesection-observer.directive";
import { ActionTrackingDirective } from "../../../directives/action-tracking.directive";
import { NavAnchorDirective } from "../../../directives/nav-anchor.directive";
import { environment } from "src/environments/environment";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";

import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";


@Component({
    selector: 'property-listing',
    templateUrl: './property-listing.component.html',
    styleUrls: ['./property-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NavAnchorDirective, ActionTrackingDirective, NgClass, PropertyListingImageComponent, IntersectionObserverDirective, NgStyle, PropertyFeaturesComponent, FavouriteSwitchComponent, NgFor, SafeUrlPipe, PbIconComponent]
})

export class PropertyListingComponent implements OnInit, OnDestroy {
    private _listing: PropertyListing;

    private adaptiveStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ADAPTIVE_STATE_CHANGED,
        On: (data: AdaptiveStateChangedMessageData) => {
            this.isMobile = this.platformService.IsMobile;
            this.cdr.markForCheck();
        }
    }

    isMobile: boolean = true;
    isMobileApp: boolean = false;
    imageSize: ImageSize = new ImageSize(480, 365);
    get imageSizeString(): string {
        return this.imageSize.width + "x" + this.imageSize.height;
    }


    @Input() set listing(value: PropertyListing) {
        if (value && value.photosTotal > 0) {
            
            if (value.enquiry) {
                var enquiry = EnquiryItem.GetTagText(value.enquiry.enquirySubType, ShortElapsedTimeDisplayWithTime(value.enquiry.enquiryDate, false));
                this.enquiryCaption = enquiry.caption;
                this.enquiryIconClass = enquiry.iconClass;
            }
            if (value.listingAdType === "Premium") {
                this.imageSize = new ImageSize(480, this.isMobile ? 480 : 365);
            }
        }
        this._listing = value;
    }
    
    get listing(): PropertyListing {
        return this._listing;
    }

    @Input() set isFavourite(value: boolean) {
        if (this.listing){
            this.listing.isFavourite = value;
            this.cdr.markForCheck();
        }
        
    }
    get isFavourite(): boolean {
        if (this.listing){
            return this.listing.isFavourite;
        }
        return false;
        
    }

    private _mode: string;
    @Input() set mode(value: string) {
        this._mode = value;
    }
    get mode(): string {
        return this._mode;
    }

    @Input() isSponsored: boolean = false;

    @Input() set forceLoadImage(value: boolean) {
        if (!this.platformService.IsBrowserPlatform) {
            this.lazyLoadImage = !value;
        }
    }

    @Input() customClickAction: ActionAndContext<PropertyListing>;

    get elapsedTimeDisplay(): string {
        return ElapsedTimeDisplay(this.listing.firstPublishedDate);
    }

    mainPhotoUrl: string;
    enquiryCaption: string;
    enquiryIconClass: string;

    get featuredPhotos(): Array<string> {
        if (!this.listing.photos) { return []; }
        return this.listing.photos.slice(0, Math.min(this.listing.photos.length, 8));
    }

    get featuredHiddenPhotoCount(): number {
        if (!this.listing.photos) { return 0; }
        return this.listing.photos.length - 8;
    }

    get featuredPhotosMobile(): Array<string> {
        if (!this.listing.photos) { return []; }
        return this.listing.photos.slice(0, Math.min(this.listing.photos.length, 2));
    }

    get featuredHiddenPhotoCountMobile(): number {
        if (!this.listing.photos) { return 0; }
        return this.listing.photos.length - 2;
    }

    get premiumPhotos(): Array<string> {
        if (!this.listing.photos) { return []; }
        return this.listing.photos.slice(0, Math.min(this.listing.photos.length, 3));
    }

    get premiumPhotosMobile(): Array<string> {
        if (!this.listing.photos) { return []; }
        return this.listing.photos.slice(0, Math.min(this.listing.photos.length, 6));
    }

    get premiumPhotosGrid(): Array<string> {
        if (!this.listing.photos) { return []; }
        return this.listing.photos.slice(0, Math.min(this.listing.photos.length, 4));
    }

    get detailsUrl(): string {
        let result = this.listing.detailsUrl;
        if (this.isSponsored) {
            result = this.urlHelpers.AddQueriesToPath(result, [new KeyValuePair('referrer', this.listing.eCommerceTracking.clientListForActions), new KeyValuePair('page', this.trackingService.GetCurrentPageName())]);

            if (this.listing.campaignId>0){
              result = this.urlHelpers.AddQueriesToPath(result, [new KeyValuePair('campaignId', this.listing.campaignId.toString())]);
            }

            let mobileTracking: any = this.trackingService.GetMobileAppValues();
            if (mobileTracking) {
                result = this.urlHelpers.AddQueriesToPath(result, [new KeyValuePair('mobileAppOs', mobileTracking.mobileAppOs), new KeyValuePair('mobileAppVersion', mobileTracking.mobileAppVersion)]);
            }
        }
        return result;
    }


    constructor(
        private cdr: ChangeDetectorRef,
        private messenger: Messenger,
        private trackingService: TrackingService,
        private applicationStateService: ApplicationStateService,
        private platformService: PlatformHelpersService,
        
        private urlHelpers: UrlHelpersService
        ) {
        this.isMobile =  this.platformService.IsMobile;

        this.isMobileApp = this.applicationStateService.isMobileApp;
    }

    ngOnInit() {
        this.messenger.Subscribe(this.adaptiveStateChangedSubscriber);
        this.lazyLoadImage = this.platformService.IsBrowserPlatform && 'IntersectionObserver' in window;
        //this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.adaptiveStateChangedSubscriber);
    }

    OnClicked(): void {
        if (this.customClickAction) {
            this.customClickAction.args = this.listing;
            this.customClickAction.Execute();
            return;
        }
        this.trackingService.SetCurrentDetailsReferrer(this.trackingService.GetCurrentPageName());
        this.trackingService.TrackProductClick(this.listing.eCommerceTracking);
    }

    IsFavouriteClicked(value: boolean) {
        this.isFavourite = value;
        if (value) {
            this.applicationStateService.AddToFavourites(this.listing.placeId).then(result => {
                this.isFavourite = result;
            });
        }
        else {
            this.applicationStateService.RemoveFromFavourites(this.listing.placeId).then(result => {
                this.isFavourite = result;
            });
        }
    }

    _lazyLoadImage = true;
    set lazyLoadImage(value: boolean) {
        this._lazyLoadImage = value;
    }
    get lazyLoadImage(): boolean {
        return !!this._lazyLoadImage;
    }

    OnSetupObserverAction = new ActionAndContext<void>(() => {
        this.lazyLoadImage = true;
    }, this, null);

    OnObservedAction = new ActionAndContext<void>(() => {
    //this.image.LoadLazyImage();

    if (this.listing.photosTotal>0){
        this.mainPhotoUrl = this.listing.photos[0];
        this.cdr.detectChanges();
    }
    
    }, this, null);
}


