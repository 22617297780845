import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { IReusableContainer } from "src/app/infrastructure/reusableContainer";
import { Subscription } from "rxjs";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MeStateChangedMessageData, SavedSearchesUpdatedMessageData } from "src/app/infrastructure/message.data.types";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { PlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { RemoveSubscriptions } from "src/app/infrastructure/object.helpers";
import { PAGE_TRACKING_NAMES } from "src/app/infrastructure/tracking.constants";
import { Me } from "src/app/infrastructure/model/me";
import { LinkbuttonComponent } from "src/app/controls/link-button/linkbutton.component";
import { ActionTrackingDirective } from "src/app/directives/action-tracking.directive";
import { SavedSearchComponent } from "src/app/modules/isolated.components/saved-search/saved-search.component";
import { NavAnchorDirective } from "src/app/directives/nav-anchor.directive";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { ApplicationResourcesService } from "src/app/services/application.resources.module/application.resources.service";
import { AuthorizationService } from "src/app/services/authorization.module/authorization.service";
import { HeadService } from "src/app/services/head.module/head.service";
import { PlacebuzzSearchService } from "src/app/services/search.module/placebuzz.search.service";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { SearchAcrossComponent } from "src/app/modules/isolated.components/search-across/search-across.component";


@Component({
    selector: 'home',
    templateUrl: './home.container.component.html',
    styleUrls: ['./home.container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [SearchAcrossComponent, NgIf, NavAnchorDirective, SavedSearchComponent, ActionTrackingDirective, LinkbuttonComponent, NgOptimizedImage]
})
export class HomeContainerComponent implements OnInit, OnDestroy, AfterViewInit, IReusableContainer {

    constructor(
        private messenger: Messenger,
        private applicationStateService: ApplicationStateService,
        private activatedRoute: ActivatedRoute,
        private applicationResourcesService: ApplicationResourcesService,
        private authorizationService: AuthorizationService,
        private router: Router,
        private headService: HeadService,
        private placebuzzSearchService: PlacebuzzSearchService,
        private trackingService: TrackingService
    ) {}

    @ViewChild('savedSearch') savedSearchComponent: SavedSearchComponent;

    heroImageUrl: string;
    heroCaption: string;
    searchesCount: number;
    valuationsEnabled: boolean;
    areaGuidesEnabled: boolean;
    isAgent:boolean;
    isAdmin:boolean;
    me:Me;
    saleOrRent:string = "sale";
    moreSavedSearchesText: string;
    savedSearchesText: string;
    savedSearchToShow: PlaceSearchCriteria;
    private savedSearches: Array<PlaceSearchCriteria>;


    private subscriptions: Array<Subscription> = new Array<Subscription>();
    private flagSavedSearchForAlerts: boolean = false;

    private saveSearchesUpdatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SAVED_SEARCHES_UPDATED,
        On: (data: SavedSearchesUpdatedMessageData) => {
            if (data) {
                this.savedSearchToShow = data.latestSearch;
                this.savedSearches = data.searches;
                this.RenderSavedSearchesSection();
            }
        }
    }

    private meStateChangedSubscriber: ISubscriber = {
      messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
      On: (data: MeStateChangedMessageData) => {
          if (data.me) {

            if (data.me.userType=="Agent"){
              this.isAgent = true;
            }
            else {
              this.isAgent = false;
            }

            if (data.me.userType=="Admin"){
              this.isAdmin = true;
            }
            else {
              this.isAdmin = false;
            }

            this.me = data.me;
          }
      }
  }

    get searchesCountString(): string {
        return this.searchesCount < 100 ? this.searchesCount.toString() : "99+";
    }

    onSearchTypeChanged(type: string) {
        this.saleOrRent = type;
    }

    ngOnInit(): void {

        this.UpdateFromApplicationResources();
        this.OnReAttached();
    }

    OnReAttached(): void {
       
        this.applicationStateService.ResetLatestSearchResults();
        this.messenger.Subscribe(this.saveSearchesUpdatedSubscriber);
        this.messenger.Subscribe(this.meStateChangedSubscriber);
        this.savedSearchToShow = null;
        this.CreateMetaTags();
        this.SetPageTracking();
        this.GetLatestSearches();
        this.RenderSavedSearchesSection();
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(q => {
            if (this.authorizationService.isLoggedIn) {
                if (q["HasAlerts"] === "true") {
                    this.flagSavedSearchForAlerts = true;
                }
            }
        }));

        if (this.applicationStateService.me){

            if (this.applicationStateService.me.userType=="Agent" || this.applicationStateService.me.userType=="AgentUserEnquiries"){
              this.isAgent = true;
            }else{
              this.isAgent = false;
            }

            if (this.applicationStateService.me.userType=="Admin"){
              this.isAdmin = true;
            }else{
              this.isAdmin = false;
            }

            this.me = this.applicationStateService.me;
        }
    }

    OnDetached(): void {
        this.messenger.Unsubscribe(this.saveSearchesUpdatedSubscriber);
        this.messenger.Unsubscribe(this.meStateChangedSubscriber);
        RemoveSubscriptions(this.subscriptions);
    }

    ngAfterViewInit(): void {
        this.GetLatestSearches();
        this.RenderSavedSearchesSection();
        if (this.flagSavedSearchForAlerts) {
            this.flagSavedSearchForAlerts = false;
            this.ProcessPostLogin();
        }
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.meStateChangedSubscriber);
        this.messenger.Unsubscribe(this.saveSearchesUpdatedSubscriber);
    }

    RenderSavedSearchesSection() {
        if (this.savedSearches && this.savedSearches.length > 0) {
            if (!this.savedSearchToShow) { this.savedSearchToShow = this.savedSearches[0]; }
            //this.searchAcrossComponent.geoSearchInputComponent.SetSearchBoxText(this.savedSearchToShow.searchArea);
            this.searchesCount = this.savedSearches.length;
            this.savedSearchesText = "Recent searches";
            if (this.searchesCount > 0) {
                this.moreSavedSearchesText = "See all searches";
                if (this.searchesCount > 1) {
                    this.moreSavedSearchesText += " ({{{number}}} more)";
                    this.moreSavedSearchesText = this.moreSavedSearchesText.replace("{{{number}}}", (this.searchesCount - 1).toString());
                }
            }

        }
    }

    OnClickedTeaser(type: string, e: any) {
        e.preventDefault();
        var url: string = "";
        switch (type) {
            case "VALUATION": url = "/property-valuation"; this.trackingService.SetCurrentValuationReferrer(this.trackingService.GetCurrentPageName()); break;
            case "COMMUTE": url = "/commute-search"; break;
            case "AREA_GUIDES": url = "/area-guides"; break;
        }
        this.router.navigateByUrl(url);
    }

    CreateMetaTags(): void {
        this.headService.SetHead(
            "Placebuzz - Find a property for sale or to rent", [
            { content: environment.clientUrl, name: "", property: "og:url" },
            { name: "description", content: "Finding your perfect home starts with Placebuzz. Use our powerful search to find homes for sale or to rent in the UK today." },
            { property: "og:description", content: "Finding your perfect home starts with Placebuzz. Use our powerful search to find homes for sale or to rent in the UK today." }
        ], [
            {href:"https://cdn.placebuzz.com/location/houses_uk_mobile.jpg", rel:"preload"}
            // { href: "home.container.module.css", type: "text/css", preloadStyleSheet: "true" }
        ]);
    }

    SetPageTracking(): void {
        this.trackingService.SetCurrentPageName(PAGE_TRACKING_NAMES.HomePage);
        this.trackingService.SetCurrentDetailsReferrer(PAGE_TRACKING_NAMES.HomePage);
    }

    private GetLatestSearches(): void {
        var searches = new Array<PlaceSearchCriteria>();
        if (this.placebuzzSearchService.GetLocalSearches()) {
            searches = searches.concat(this.placebuzzSearchService.GetLocalSearches());
        }

        if (this.applicationStateService.me && this.applicationStateService.me.savedSearches) {
            searches = searches.concat(this.applicationStateService.me.savedSearches);
        }
        

        this.savedSearches = searches;

        this.savedSearchToShow = this.placebuzzSearchService.GetLatestSearch();
        if (!this.savedSearchToShow && this.savedSearches.length > 0) {
            this.savedSearchToShow = this.savedSearches.find(s => !!s.placeSearchCriteriaId);
            if (!this.savedSearchToShow) {
                this.savedSearchToShow = this.savedSearches[0];
            }
        }
    }


    private ProcessPostLogin(): void {
        if (this.savedSearchComponent) {
            this.savedSearchComponent.OnClickedAlerts();
        }
    }

    private UpdateFromApplicationResources(): void {
        var applicationResources = this.applicationResourcesService.GetApplicationResources();
        if (applicationResources) {
            this.heroImageUrl = this.applicationResourcesService.GetApplicationSetting("HomepageHeroUrl", "");
            this.heroCaption = this.applicationResourcesService.GetApplicationSetting("HomepageHeroCaption", "");
            this.valuationsEnabled = this.applicationResourcesService.GetApplicationSetting("Valuations", true);
            this.areaGuidesEnabled = this.applicationResourcesService.GetApplicationSetting("AreaGuides", true);
        }
    }

    
}
