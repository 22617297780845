<div class="swiper-container" (click)="onFullScreenClicked()">
    <swiper #swiper [config]="this.config" [zoom]="true">
        <ng-template *ngFor="let item of images" swiperSlide [zoom]="true">
                <a *ngIf="this.fullScreenUrl" [routerLink]="[this.fullScreenUrl]">
                    <picture *ngIf="this.mode=='image'" class="slider-photo">
                        <source [srcset]="getWebpUrl(item)" type="image/webp"/>
                        <source [srcset]="getJpgUrl(item)" type="image/jpeg" />
                        <img class="slider-image" [src]="getImageUrl(item)" width="100%" height="auto" loading="lazy" class="swiper-lazy"/>
                    </picture>
                </a>

                <picture *ngIf="!this.fullScreenUrl && this.mode=='image'" class="slider-photo">
                    <source [srcset]="getWebpUrl(item)" type="image/webp"/>
                    <source [srcset]="getJpgUrl(item)" type="image/jpeg" />
                    <img class="slider-image" [src]="getImageUrl(item)" width="100%" height="auto" loading="lazy" class="swiper-lazy"/>
                </picture>

                <div class="swiper-lazy-preloader"></div>
        </ng-template>
    </swiper>
    
    <link-button *ngIf="this.hasCounter"
        class="open-button"
        [title]="counterText"
        beforeIcon="photos"
        border="true"
        [href]="this.fullScreenUrl"
        (linkClicked)="onFullScreenClicked()"
        size="small">
    </link-button>
</div>
