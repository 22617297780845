import { GqlQueryArgument, GqlQueryPart } from "src/app/services/graphql.module/model";
import { CreateGqlQueryArguments } from "../create.query";

export const PropertySimilarListingsQuery = `
  propertySimilarListings(listingId:$listingId){
    price,
    weeklyPrice,
    priceModifier,
    title,
    detailsUrl,
    photos,
    subTitle,
    address,
    eCommerceTracking{
      id,
      name,
      brand,
      dimension1,
      category,
      coupon,
      variant,
      price,
      event,
      affiliation
    }
  }
`;
export const PropertySimilarListingsQueryArgs: Array<GqlQueryArgument> = [
  { variableName: "$listingId", variableType: "String" }
];
export function CreatePropertySimilarListingsQueryPart(listingId: string): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: PropertySimilarListingsQuery,
    arguments: CreateGqlQueryArguments(PropertySimilarListingsQueryArgs, [listingId])
  };
  return result;
};