import { Component, OnDestroy, Input, Inject, NgModule } from '@angular/core';
import { ISubscriber } from 'src/app/services/messenger.module/model/model';
import { MESSAGE_TYPES } from 'src/app/infrastructure/message.types';
import { ShowInAppToastMessageData } from 'src/app/infrastructure/message.data.types';
import { NgClass } from '@angular/common';
import { Messenger } from 'src/app/services/messenger.module/messenger';

@Component({
    selector: 'in-app-toast',
    templateUrl: './in-app-toast.component.html',
    styleUrls: ['./in-app-toast.component.scss'],
    standalone: true,
    imports: [NgClass]
})

export class InAppToastComponent implements OnDestroy {
    private _isVisible: boolean;
    private lastTimeOut: any;
    private showInAppToastSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_IN_APP_TOAST,
        On: (data: ShowInAppToastMessageData) => {
            this.text = data.text;
            this.isVisible = true;
        }
    }

    @Input() text: string;
    @Input() set isVisible(value: boolean) {
        if (value === true) {
            try {
                if (this.lastTimeOut && this._isVisible === true) {
                    clearTimeout(this.lastTimeOut);
                }
                this._isVisible = value;
                this.lastTimeOut = setTimeout(() => {
                    this._isVisible = false;
                }, 3000);
            }
            catch{ }
        }
        else {
            this._isVisible = value;
        }
    }
    get isVisible(): boolean {
        return this._isVisible;
    }

    constructor(
        private messenger: Messenger
    ) {
        this.messenger.Subscribe(this.showInAppToastSubscriber);
        this._isVisible = false;
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.showInAppToastSubscriber);
    }

    OnTapped() {
        this.isVisible = false;
    }
}


