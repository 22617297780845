import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PlatformHelpersService } from '../platform.helpers.module/platform.helpers.service';

@Injectable({
  providedIn: 'root'
})
export class AdScriptLoaderService {
  private renderer: Renderer2;
  private loadedScripts: Set<string> = new Set();

  constructor(
      rendererFactory: RendererFactory2, 
      private platformHelpersService: PlatformHelpersService,
      @Inject(PLATFORM_ID) private platformId: Object) 
  {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  shouldIncludeAdScript(): boolean {
    const userAgent = window.navigator.userAgent;
    const isPlacebuzzAutomatedTest = userAgent.includes("Placebuzz-Specs");
    const isMobileApp = userAgent.includes("Placebuzz.Mob.App");
    const fullUrl = window.location.href;

    if (!isPlacebuzzAutomatedTest && !isMobileApp && (fullUrl.toLowerCase().includes("://www.placebuzz.com"))) {
      return true;
    }
    return false;
  }

  loadScript(): Promise<void> {
    if (isPlatformBrowser(this.platformId) && this.shouldIncludeAdScript()) {

      var inMobiScript = "!function(){var e=window.location.hostname,t=document.createElement('script'),a=document.getElementsByTagName('script')[0],i='https://cmp.inmobi.com'.concat('/choice/','GXUa3mf4SHptJ','/',e,'/choice.js?tag_version=V3'),s=0;t.async=!0,t.type='text/javascript',t.src=i,a.parentNode.insertBefore(t,a),!function e(){for(var t,a='__tcfapiLocator',i=[],s=window;s;){try{if(s.frames[a]){t=s;break}}catch(n){}if(s===window.top)break;s=s.parent}t||(!function e(){var t=s.document,i=!!s.frames[a];if(!i){if(t.body){var n=t.createElement('iframe');n.style.cssText='display:none',n.name=a,t.body.appendChild(n)}else setTimeout(e,5)}return!i}(),s.__tcfapi=function e(){var t,a=arguments;if(!a.length)return i;if('setGdprApplies'===a[0])a.length>3&&2===a[2]&&'boolean'==typeof a[3]&&(t=a[3],'function'==typeof a[2]&&a[2]('set',!0));else if('ping'===a[0]){var s={gdprApplies:t,cmpLoaded:!1,cmpStatus:'stub'};'function'==typeof a[2]&&a[2](s)}else'init'===a[0]&&'object'==typeof a[3]&&(a[3]=Object.assign(a[3],{tag_version:'V3'})),i.push(a)},s.addEventListener('message',function e(t){var a='string'==typeof t.data,i={};try{i=a?JSON.parse(t.data):t.data}catch(s){}var n=i.__tcfapiCall;n&&window.__tcfapi(n.command,n.version,function(e,i){var s={__tcfapiReturn:{returnValue:e,success:i,callId:n.callId}};a&&(s=JSON.stringify(s)),t&&t.source&&t.source.postMessage&&t.source.postMessage(s,'*')},n.parameter)},!1))}();var n=function(){var e=arguments;typeof window.__uspapi!==n&&setTimeout(function(){void 0!==window.__uspapi&&window.__uspapi.apply(window.__uspapi,e)},500)},p=function(){s++,window.__uspapi===n&&s<3?console.warn('USP is not accessible'):clearInterval(r)};if(void 0===window.__uspapi){window.__uspapi=n;var r=setInterval(p,6e3)}}();";

      
      
      return new Promise((resolve, reject) => {
        
        switch (this.platformHelpersService.adsProvider) {
          case 'admanager':
            this.loadInlineScript(inMobiScript).then(() => {
              return this.loadUrlScript("https://securepubads.g.doubleclick.net/tag/js/gpt.js");
            }).then(resolve).catch(reject);
            break;
          case 'fuse':
            this.loadUrlScript("https://cdn.fuseplatform.net/publift/tags/2/2178/fuse.js").then(resolve).catch(reject);
            break;
          case 'tude':
            this.loadInlineScript(inMobiScript).then(() => {
              return this.loadUrlScript("https://web.prebidwrapper.com/insticator-VzuFOd1eZt/placebuzz-longform/prebid-load.js");
            }).then(resolve).catch(reject);
            break;
          default:
            resolve();
            break;
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  loadUrlScript(url: string): Promise<void> {
    if (this.loadedScripts.has(url)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      script.onload = () => {
        this.loadedScripts.add(url);
        resolve();
      };
      script.onerror = (error) => reject(error);
      this.renderer.appendChild(document.head, script);
    });
  }

  loadInlineScript(scriptContent: string): Promise<void> {
    const hash = this.hashCode(scriptContent);

    if (this.loadedScripts.has(hash)) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.text = scriptContent;
      document.head.appendChild(script);
      this.loadedScripts.add(hash);
      resolve();
    });
  }

  private hashCode(str: string): string {
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
  }
}
