import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RemoveSubscriptions } from 'src/app/infrastructure/object.helpers';
// import { AdScriptLoaderService } from 'src/app/services/ad.script.loader.service/ad.script.loader.service';
import { AdsService } from 'src/app/services/ads.service.module/ads.service';
import { BreakpointService } from 'src/app/services/breakpoint.module/breakpoint.service';
import { DoubleClickAdsService } from 'src/app/services/double.click.ads.module/double.click.ads.service';
import { LoggingService } from 'src/app/services/logging.module/logging.service';
import { PlatformHelpersService } from 'src/app/services/platform.helpers.module/platform.helpers.service';

@Component({
  template: '',
  standalone: true,
  providers: [
    AdsService,
    LoggingService,
    BreakpointService,
    // AdScriptLoaderService
  ]
})


export class AdsBasePageComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        protected adsService: AdsService,
        protected platformHelpersService: PlatformHelpersService,
        protected loggingService: LoggingService,
        protected breakpointService: BreakpointService,
        // protected adScriptService: AdScriptLoaderService,
        protected router: Router,
        protected doubleClickAdsService: DoubleClickAdsService,
    ) { 

        
        this.adssubscriptions.push(this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                if (this.platformHelpersService.adsProvider === 'fuse') {
                    this.loggingService.LogToDevConsole("<Ads-Base-Page>initializeFuseTag</Ads-Base-Page>");  
                    this.adsService.initializeFuseTag(this.adsPage);
                }

                if (this.platformHelpersService.adsProvider === 'admanager') {
                    this.loggingService.LogToDevConsole("<Ads-Base-Page>Clearing ads</Ads-Base-Page>");  
                    this.doubleClickAdsService.ClearAds();
                }
                
        }));
        

        

    }
    ngOnInit(): void {
        // if (this.platformHelpersService.adsProvider === 'admanager') {
        //     this.loggingService.LogToDevConsole("<Ads-Base-Page>initializeAdManager</Ads-Base-Page>");  
        //     this.doubleClickAdsService.InitialisePageAds(this.adsPage, this.adsPage);
        // }
    }
    ngAfterViewInit(): void {
        
            // this.adScriptService.loadScript();
        
    }

    private adssubscriptions: Array<Subscription> = new Array<Subscription>();
    protected adsPage: string = "";

    ngOnDestroy(): void {
        this.loggingService.LogToDevConsole("<Ads-Base-Page>ngOnDestroy</Ads-Base-Page>");
        RemoveSubscriptions(this.adssubscriptions);
    }

    protected registerZone(divId:string){
        if (this.platformHelpersService.adsProvider === 'fuse') {
            this.adsService.registerZone(divId);
        }
    }

    protected registerAllZones(){
        if (this.platformHelpersService.adsProvider === 'fuse') {
            this.adsService.registerAllZones();
        }
    }

    protected destroyZone(divId:string){
        if (this.platformHelpersService.adsProvider === 'fuse') {
            this.adsService.destroyZone(divId);
        }
    }

}