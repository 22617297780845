import { Input, Directive} from '@angular/core';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';

@Directive()
export abstract class SwiperComponentBase {
    
    private _imageSizeLarge = "960x640";
    private _imageSizeMedium = "768x512";
    private _imageSizeSmall = "480x320";
    private _imageSizeTiny = "300x200";
    private _imageSizeSuperTiny = "150x100";
    private _imageSize = this._imageSizeSmall;
    private _imageSizeTablet = this._imageSizeTiny;
    private _imageSizeMobile = this._imageSizeSuperTiny;
    private cropImagePrefix = "c";


    config: SwiperOptions = {
        lazy: true,
        spaceBetween: 0,
        slidesPerView:1,
        zoom: true
      };

      breakpoint: any = {
        320:{
          slidesPerView:2.5
        },
        480:{
          slidesPerView:2.5
        },
        767:{
          slidesPerView:2.5
        },
        1023:{
          slidesPerView: 4.5
        },
      }
   
      @Input() set spaceBetween(value:number){
          this.config.spaceBetween = value;
      }

      
    
      @Input() set height(value: number){
        this.config.height = value;
      }

      @Input() set initialSlide(value: number){
        this.config.initialSlide = value-1;
      }

      @Input() set scrollable(value: boolean) {
        if (value){
          this.config.scrollbar = { draggable: true }
        }
      }
    
      @Input() set pagination(value: boolean){
        if (value){
          this.config.pagination = { clickable: true }
        }
      }

      @Input() set cropPhotos(value: string){
        if (value=="true"){
          this.cropImagePrefix = "c";
        }
        else{
          this.cropImagePrefix = "";
        }
      }
    
      @Input() set navigation(value: boolean){
        if (value){
          this.config.navigation = true;
        }
      }
    
      @Input() set slidesPerView(value: number){
        this.config.slidesPerView = value;
      }

      @Input() set reduceSlidesOnMobile(value: boolean){
        if (value){
          this.config.breakpoints= this.breakpoint;
        }
      }



    @Input () set imageSizes (value: string) {
        switch (value) {
        case "large":{
            this._imageSize = this._imageSizeLarge;
            this._imageSizeTablet = this._imageSizeMedium;
            this._imageSizeMobile = this._imageSizeSmall;
            break;
        }
        case "medium":{
            this._imageSize = this._imageSizeMedium;
            this._imageSizeTablet = this._imageSizeSmall;
            this._imageSizeMobile = this._imageSizeTiny;
            break;
        }
        case "small":{
            this._imageSize = this._imageSizeSmall;
            this._imageSize = this._imageSizeSmall;
            this._imageSizeMobile = this._imageSizeTiny;
            break;
        }
        case "tiny":{
            this._imageSize = this._imageSizeTiny;
            this._imageSizeTablet = this._imageSizeTiny;
            this._imageSizeMobile = this._imageSizeSuperTiny;
            break;
        }
        }
    };

    getWebpUrl(imageUrl: string) {
        if (!imageUrl) return null;

  
        return imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSize + '/photo/').replace('.jpg', '.webp') + ' 1024w,' + 
                imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSizeTablet + '/photo/').replace('.jpg', '.webp') + ' 768w,' + 
                imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSizeMobile + '/photo/').replace('.jpg', '.webp') + ' 480w';

      }
    
      getJpgUrl(imageUrl: string) {
        if (!imageUrl) return null;

        return imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSize + '/photo/') + ' 1024w,' + 
                imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSizeTablet + '/photo/') + ' 768w,' + 
                imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSizeMobile + '/photo/') + ' 480w';
      }
    
      getImageUrl(imageUrl: string) {
        if (!imageUrl) return null;
        
        return imageUrl.replace('/photo/', "/" + this.cropImagePrefix + this._imageSize + '/photo/');
      }



}