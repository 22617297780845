import { Component, OnDestroy, OnInit, ElementRef, ViewChild, ComponentFactoryResolver, Injector, Inject, EventEmitter, NgModule, ViewEncapsulation, AfterViewInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { AdaptiveStateChangedMessageData } from "src/app/infrastructure/message.data.types";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { RemoveSubscriptions } from "src/app/infrastructure/object.helpers";
import { DynamicComponentContainerComponentBase } from "src/app/modules/component.base.classes/dynamic.component.container.component.base";
import { NgClass, NgIf } from "@angular/common";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";

@Component({
    selector: 'pop3up',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgClass, NgIf, PbIconComponent]
})
export class PopupComponent extends DynamicComponentContainerComponentBase implements OnInit, OnDestroy, AfterViewInit {
    private subscriptions: Array<Subscription> = new Array<Subscription>();
    @ViewChild('popupDiv', { static: true }) private popupDiv: ElementRef;

    private adaptiveStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ADAPTIVE_STATE_CHANGED,
        On: (data: AdaptiveStateChangedMessageData) => {
            this.Size();
            
        }
    }

    popupWidth: string = "0";
    popupHeight: string = "0";
    shouldStartAnimating: boolean = false
    canUserClose: boolean;
    supportsTransform: boolean = false;
    isNoFixedHeight: boolean = false;
    display: string = "none";

    constructor(
        resolver: ComponentFactoryResolver,
        injector: Injector,
        private urlHelpersService: UrlHelpersService,
        private messenger: Messenger,
        private platformService: PlatformHelpersService
    ) {
        super(resolver, injector);
    }
    ngAfterViewInit(): void {
        this.platformService.ScrollToTop();
    }

    ngOnInit(): void {
        this.messenger.Subscribe(this.adaptiveStateChangedSubscriber);
        this.supportsTransform = this.platformService.SupportsTransformTranslate;
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.adaptiveStateChangedSubscriber);
        RemoveSubscriptions(this.subscriptions);
        this.ProcessNoFixedHeightMobile(false);
    }

    OnClickedX(e: any) {
        if (e && e.preventDefault && e.stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.urlHelpersService.RemovePopupVisible();
        this.messenger.Send({
            messageType: MESSAGE_TYPES.CLOSE_POPUP,
            messageData: {}
        });
    }

    Size() {
        if (this.currentComponent != undefined) {
            this.currentComponent.instance.isInPopup = true;
            this.currentComponent.instance.opacity = 1;

            this.shouldStartAnimating = true;
            var comp = this.currentComponent.instance;
            comp.shouldStartAnimating = true;
            this.popupWidth = this.platformService.IsMobile ? "100%" : comp.popupWidth;
            this.display = "block";
            this.isNoFixedHeight = comp.isNoFixedHeight;
            this.canUserClose = comp.canUserClose;

            // if (this.isNoFixedHeight) {
            //     this.popupHeight = "auto";
            // }
            // else {
            //     this.popupHeight = this.navigationService.IsMobileAdaptiveState() ? "100%" : comp.popupHeight;
            // }

            var self = this;
            if (this.platformService.IsBrowserPlatform) {
                this.platformService.GetElementRectangleWhenAvailable(document.body, () => {
                    self.ProcessNoFixedHeightMobile(true);
                    self.shouldStartAnimating = false;
                    comp.shouldStartAnimating = false;
                });
            }
        }
    }

    override GetContainedComponentInstance() {
        return this.currentComponent.instance;
    }

    protected override OnLoadedComponentOverride() {
        var popupContainedComponent = this.currentComponent.instance as PopupContainedComponent;
        RemoveSubscriptions(this.subscriptions);
        this.subscriptions.push(popupContainedComponent.SizesChangedEvent.asObservable().subscribe(s => {
            this.Size();
        }));
    }

    private ProcessNoFixedHeightMobile(isShown: boolean) {
        if (this.platformService.IsBrowserPlatform && this.isNoFixedHeight) {
            var height = "auto"
            this.platformService.GetElementRectangleWhenAvailable(this.popupDiv.nativeElement, () => {
                this.platformService.ScrollToTop();
            });
        }
        this.platformService.ScrollToTop();
    }
}

export interface PopupContainedComponent {
    opacity: number;
    popupWidth: string;
    popupHeight: string;
    mobilePopupWidth: string;
    mobilePopupHeight: string;
    SizesChangedEvent: EventEmitter<boolean>;
    shouldStartAnimating: boolean;
    parameters: any;
    isInPopup: boolean;
    canUserClose: boolean;
    isNoFixedHeight: boolean;
}


