import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, ViewChild, ViewEncapsulation} from "@angular/core";
import { SocialSharingComponent } from "src/app/modules/isolated.components/social-sharing/social-sharing.component";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { RemoveSubscriptions} from "src/app/infrastructure/object.helpers";
import { SESSION_STORAGE_KEYS, BROWSER_STORAGE_KEYS } from "src/app/infrastructure/storage.keys";
import { PAGE_TRACKING_NAMES } from "src/app/infrastructure/tracking.constants";
import { MortgageEstimate } from "src/app/infrastructure/model/mortgageEstimate";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { PropertyDetails } from "./model";
import { EnquiryItem } from "src/app/modules/isolated.components/enquiry.item.component/model";
import {AffiliateAdType} from 'src/app/components/affiliate/affiliateAdType'
import { LoggingService } from "src/app/services/logging.module/logging.service";
import { Location, NgIf } from '@angular/common';
import { AdsBasePageComponent } from "src/app/base-pages/ads-base-page/ads-base-page.component";
import { ShortElapsedTimeDisplayWithTime } from "src/app/infrastructure/date.helpers";
import { SafeUrlPipe } from "../../../pipes/safe.url.pipe";
import { FavouriteSwitchComponent } from "src/app/controls/favourite-switch/favourite-switch.component";
import { SocialSharingComponent as SocialSharingComponent_1 } from "../../../modules/isolated.components/social-sharing/social-sharing.component";
import { LinkbuttonComponent } from "../../../controls/link-button/linkbutton.component";
import { MetaTagsServiceService } from "src/app/services/meta.tags.module/meta.tags.service.service";
import { PropertyDetailsService } from "src/app/services/property.details.module/property.details.service";
import { AdsService } from "src/app/services/ads.service.module/ads.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { BrowserStorageService } from "src/app/services/browser.storage.module/browser.storage.service";
import { HeadService } from "src/app/services/head.module/head.service";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { EnquiriesService } from "src/app/services/enquiries.service.module/enquiries.service";
import { HistoryService } from "src/app/services/history.service.module/history.service";
import { BreakpointService } from "src/app/services/breakpoint.module/breakpoint.service";
import { environment } from "src/environments/environment";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { AdUnitComponent } from "src/app/components/ad-unit/ad-unit.component";
import { DoubleClickAdsService } from "src/app/services/double.click.ads.module/double.click.ads.service";



@Component({
    selector: 'property-details-page',
    templateUrl: './property-details-page.component.html',
    styleUrls: ['./property-details-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        LinkbuttonComponent,
        SocialSharingComponent_1,
        FavouriteSwitchComponent,
        RouterOutlet,
        SafeUrlPipe,
        AdUnitComponent
    ],
})
export class PropertyDetailsPageComponent extends AdsBasePageComponent implements OnInit, OnDestroy, AfterViewInit {
    private _socialSharingComponents: QueryList<SocialSharingComponent>;
    initialSlide: number;
    

    @ViewChildren(SocialSharingComponent) private set socialSharingComponents(value: QueryList<SocialSharingComponent>) {
        this._socialSharingComponents = value;
        this.SetSharingComponentProperties();
    }

    private get socialSharingComponents(): QueryList<SocialSharingComponent> {
        return this._socialSharingComponents;
    }
    @ViewChildren(AdUnitComponent) adUnits: QueryList<AdUnitComponent>;
    @ViewChild('socialSharingMobile') private socialSharingMobile: SocialSharingComponent;

    sliderAspectRatio = 576 / 860;
    private subscriptions: Array<Subscription> = new Array<Subscription>();
    propertyId: string;
    contactAgentUrl :string;
    requestDetailsUrl:string;
    mapMode:string = "map";
    page:string = "property-details";
    private placeIdToAddToFavourites: number;
    public adTypes = AffiliateAdType;
    public showTheAds:boolean = true;
    isDescTruncated: boolean = false;
    showMap: boolean = false;
    showStreetview:boolean = false;

    protected propertyTypeId: number;

    propertyDetails: PropertyDetails;
    hasFinishedLoading: boolean = false;
    isMobile: boolean;
    isMobileApp: boolean = false;
    isEstimatingMortgage: boolean = false;
    poweredByDisclaimer: string;
    isFavourite: boolean = false;
    showTestAds:boolean = false;
    
    
    backButtonText: string;

    revealButtonText: string;
    isPhoneRevealed: boolean = false;
    listerLogoUrl: string;


    
    private OnActivatedRouteParamsChanged() {
        this.loggingService.LogToDevConsole("<property-details> OnActivatedRouteParamsChanged </property-details>");
        this.PerformGetDetailsRequest();
    }      

    get isForSale(): boolean {
        return this.propertyTypeId === 2;
    }


    get hasPreviousPage(): boolean {
        return false;
    }


    constructor(
        private activatedRoute: ActivatedRoute,
        
        private applicationStateService: ApplicationStateService,
        private browserStorageService: BrowserStorageService,
        private headService: HeadService,
        private trackingService: TrackingService,
        adsService: AdsService,
        private platformService: PlatformHelpersService,
        private urlHelpersService: UrlHelpersService,
        private enquiriesService: EnquiriesService,
        loggingService: LoggingService,
        private metaTagsService: MetaTagsServiceService,
        private propertyDetailsService: PropertyDetailsService,
        private historyService: HistoryService,
        breakpointService: BreakpointService,
        doubleClickAdsService: DoubleClickAdsService,
        router: Router,
        private location: Location,
        private messenger: Messenger,
        

    ) {
        super(adsService, platformService, loggingService, breakpointService, router, doubleClickAdsService);
        this.adsPage = "property_details";
        this.RegisterSubscriptions();
    }

//#region Click Events

    OnClickedShare(e) {
        this.socialSharingMobile.openPopup(e);
        this.platformService.ScrollToTop();
    }

    onFullScreenClicked(){
        this.router.navigate([this.propertyDetails.detailsUrl + "/images"]);
    }

    OnClickedPrint(e) {
        (<any>window).print();
    }

    isDesktopHd(){
        return this.breakpointService.currentBreakpoint === 'desktop';
    }

    OnClickedBack() {

        var currentUrl = this.historyService.getCurrentUrl();
        var prevUrl = this.historyService.getPreviousUrl();

        if (prevUrl){
            if (prevUrl.indexOf("/images") > -1 || prevUrl.indexOf("/floorplans") > -1 || prevUrl.indexOf("/documents") > -1) {
                this.historyService.popLastUrlFromHistory();
                prevUrl = this.historyService.getPreviousUrl();
                if (prevUrl){
                    this.location.back();
                    return;        
                }
                this.router.navigateByUrl(this.propertyDetails.similarPropertiesSearchUrl);
                return;
            } 

            this.location.back();

        }
        else{
            if (currentUrl.indexOf("/images") > -1 || currentUrl.indexOf("/floorplans") > -1 || currentUrl.indexOf("/documents") > -1) {
                this.router.navigateByUrl(this.propertyDetails.detailsUrl);
                return;
            } 
            this.router.navigateByUrl(this.propertyDetails.similarPropertiesSearchUrl);
        }
    }


    get showStickyBanner (){
        return this.platformHelpersService.adsProvider === 'tude' || this.platformHelpersService.adsProvider === 'admanager';
    }


    IsFavouriteClicked(value: boolean) {
        this.isFavourite = value;
        if (value) {
            this.applicationStateService.AddToFavourites(this.propertyDetails.listingId).then(result => {
                this.isFavourite = result;
            });
        }
        else {
            this.applicationStateService.RemoveFromFavourites(this.propertyDetails.listingId).then(result => {
                this.isFavourite = result;
            });
        }
    }

    
//#endregion



    override ngOnInit(): void {

        super.ngOnInit();
        this.loggingService.LogToDevConsole("<property-details> OnInit </property-details>");

        this.isMobileApp = this.applicationStateService.isMobileApp;
       
        this.SetPlaceIdToAddToFavourites();

        this.showTestAds = this.platformService.IsAutomatedTestUserAgent || this.platformService.IsLocalhost;
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.loggingService.LogToDevConsole("<property-details> OnDestroy </property-details>");
        this.UnregisterSubscriptions();

    }

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.SetSharingComponentProperties();

    }   

    SetPageTracking(): void {
        var mapSearchGTM = this.browserStorageService.GetSession(SESSION_STORAGE_KEYS.MAP_TO_PROPERTY_NAVIGATION_TRACKING);
        this.browserStorageService.SetSession(SESSION_STORAGE_KEYS.MAP_TO_PROPERTY_NAVIGATION_TRACKING, null);
        if (mapSearchGTM && mapSearchGTM.id === this.propertyDetails.listingId) {
            this.trackingService.SetCurrentDetailsReferrer(mapSearchGTM.page);
        }

        this.trackingService.SetCurrentPageName(this.isForSale ? PAGE_TRACKING_NAMES.PropertyDetailsForSale : PAGE_TRACKING_NAMES.PropertyDetailsForRent);
        if (!this.trackingService.GetCurrentDetailsReferrer()) {
            this.trackingService.SetCurrentDetailsReferrer(this.trackingService.GetCurrentPageName());
        }

        this.propertyDetails.eCommerceTracking.position = 1;
        this.trackingService.TrackDetailDisplay(this.propertyDetails.eCommerceTracking);
    }

    private RegisterSubscriptions() {
        
        this.loggingService.LogToDevConsole("<property-details> Registering Subscriptions </property-details>");

        this.subscriptions.push(this.activatedRoute.paramMap.subscribe(params => {
            const listingId = params.get('propertyId'); // Or however you get the listing ID
                if (listingId) {
                    // Do something with the listing ID
                    this.loggingService.LogToDevConsole("<property-details> Found listingId in Params </property-details>");
                    this.propertyId = listingId;
                    this.OnActivatedRouteParamsChanged();
                }
            })
        );

        

        this.contactAgentUrl = '/contact-agent/' + this.propertyId;
        this.requestDetailsUrl = '/contact-agent/' + this.propertyId + "?mode=request_details_step1";

        this.messenger.Subscribe(this.meStateChangedSubscriber);
        this.messenger.Subscribe(this.favouritesUpdatedSubscriber);
    }

    private UnregisterSubscriptions() {
        RemoveSubscriptions(this.subscriptions);
        this.messenger.Unsubscribe(this.meStateChangedSubscriber);
        this.messenger.Unsubscribe(this.favouritesUpdatedSubscriber);
    }

    private InitializeIsFavourite() {
        if (this.applicationStateService.me && this.applicationStateService.me.favourites) {
            this.isFavourite = !!this.applicationStateService.me.favourites.find(f => f.placeId === this.propertyDetails.listingId);
        }
    }

    enquiryCaption: string;
    enquiryIconClass: string;
    private InitializeEnquiry() {
        if (this.propertyDetails && this.propertyDetails.listingId){
            let enquiry = this.enquiriesService.GetEnquiryForPlaceId(this.propertyDetails.listingId);
            if (enquiry) {
                var tagText = EnquiryItem.GetTagText(enquiry.enquirySubType, ShortElapsedTimeDisplayWithTime(enquiry.enquiryDate, false));
                this.enquiryCaption = tagText.caption;
                this.enquiryIconClass = tagText.iconClass;
            }
        }
    }

    showAds: boolean = true;
    private InitializeAds() {

        if (this.isMobileApp){
            this.showAds = false;
        }
        // else{
        //     this.showAds = this.applicationResourcesService.GetApplicationSetting("ShowAds", true);
        // }


        // if (this.showAds && this.platformService.IsBrowserPlatform) {
        //     this.doubleClickAdsService.InitialisePageAds("property_details",  this.isForSale?'Property Details - For Sale':'Property Details - To Rent');
        // }
    }

    

    // public getAd(page:string, adPosition:string){
    //     return this.doubleClickAdsService.GetAdSlot(page, adPosition);
    // }

    getState(): any {
        const navigation = this.router.getCurrentNavigation();
        const state = navigation?.extras.state as any;
        if (state){
            return state;
        }
        else{
            return null;
        }
    }

    private PerformGetDetailsRequest() {

        var currentPath = this.activatedRoute.snapshot.parent.url;

        this.propertyId = currentPath[2].path;

        
        

        if (!this.propertyDetails || this.propertyDetails.listingId.toString() !== this.propertyId) {
            var browserMortgageSettings = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.MORTGAGE_CALCULATOR) as MortgageEstimate;
            
            if (browserMortgageSettings) {
                delete browserMortgageSettings.currencySymbol;
                delete browserMortgageSettings.estimatedMonthlyMortgage;
                delete browserMortgageSettings.estimatedMonthlyMortgageInfo;
                delete browserMortgageSettings.propertyPrice;
                delete browserMortgageSettings.repaymentPeriods;
            }

            var campaignId = 0;


            if (this.activatedRoute.snapshot.queryParams['campaignId']){
                campaignId  = parseInt(this.activatedRoute.snapshot.queryParams['campaignId']);
            }


            this.propertyDetailsService.getAndStorePropertyDetails(this.propertyId, browserMortgageSettings, campaignId).then(result => {

                this.propertyDetails = result;


                if (this.platformService.IsBrowserPlatform &&  this.propertyDetails.isRedirectToPartner){
                    this.trackingService.TrackDetailDisplay(this.propertyDetails.eCommerceTracking);
                    this.trackingService.TrackPPCConversion(this.propertyDetails.eCommerceTracking,this.propertyDetails.partnerRedirectUrl, "SearchResults",this.propertyDetails.eCommerceTracking.affiliation);
                    
                    setTimeout(() => {
                        window.location.replace(this.propertyDetails.partnerRedirectUrl);
                    }, 300);
                    return;
                }

                if (this.propertyDetails.listerLogoUrl) {
                    if (this.propertyDetails.listerLogoUrl.toLowerCase().indexOf("://") > -1) {
                        this.listerLogoUrl = this.propertyDetails.listerLogoUrl;
                    }
                    else {
                        this.listerLogoUrl = environment.mediaServerUrl + "/" + this.propertyDetails.listerLogoUrl;
                    }
                }

                this.metaTagsService.CreatePropertyDetailsMetaTags(this.propertyDetails, this.headService);

                this.SetPageTracking();

                this.poweredByDisclaimer ="DISCLAIMER: Property descriptions and related information displayed on this page are marketing materials provided by {{AGENT_NAME}}. Placebuzz does not warrant or accept any responsibility for the accuracy or completeness of the property descriptions or related information provided here and they do not constitute property particulars. Please contact {{AGENT_NAME}} for full details and further information.";
                this.poweredByDisclaimer = this.poweredByDisclaimer.replace("{{AGENT_NAME}}", this.propertyDetails.agentName);
                this.poweredByDisclaimer = this.poweredByDisclaimer.replace("{{AGENT_NAME}}", this.propertyDetails.agentName);

                this.revealButtonText = this.propertyDetails.agentPhoneNumber;
                this.isPhoneRevealed = true;

                
                this.hasFinishedLoading = !this.propertyDetails.isRedirectToPartner;

                this.InitializeIsFavourite();
                this.InitializeEnquiry();
                this.AddPlaceIdToFavourites();

                this.InitializeAds();

                if (this.platformService.IsBrowserPlatform &&  this.propertyDetails.isRedirectToPartner){
                    this.trackingService.TrackPPCConversion(this.propertyDetails.eCommerceTracking,this.propertyDetails.partnerRedirectUrl, "SearchResults",this.propertyDetails.eCommerceTracking.affiliation);
                }
            });
        }
    }

    private meStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
        On: () => {
          if (this.applicationStateService.me) {
              this.InitializeIsFavourite();
          }
        }
    }

    private favouritesUpdatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.FAVOURITES_UPDATED,
        On: () => {
            this.InitializeIsFavourite();
        }
      }

    
    private SetSharingComponentProperties() {
        if (this.socialSharingComponents) {
            var sscArray = this.socialSharingComponents.toArray();
            for (var i = 0; i < sscArray.length; i++) {
                sscArray[i].shareUrl = this.propertyDetails.shareUrl;
                sscArray[i].shareContentText = "Check out this " + this.propertyDetails.title + " on Placebuzz!";
                sscArray[i].shareSocialMediaText = "Check out this " + this.propertyDetails.title + " on Placebuzz!";
                sscArray[i].shareSubjectText = "Check out this " + this.propertyDetails.title + " on Placebuzz!";
            }
        }
    }

   

    

    private SetPlaceIdToAddToFavourites() {
        this.placeIdToAddToFavourites = parseInt(this.activatedRoute.snapshot.queryParams["addFavouritePlaceId"]);
    }

    private AddPlaceIdToFavourites(): Promise<void> {
        return new Promise(resolve => {
            if (this.placeIdToAddToFavourites && this.propertyDetails && this.applicationStateService.me) {
                this.placeIdToAddToFavourites = null;
                this.applicationStateService.AddToFavourites(this.propertyDetails.listingId).then(result => {
                    this.isFavourite = result;
                    this.urlHelpersService.RemoveQueryFromLocation("addFavouritePlaceId");
                    resolve();
                });
            }
            else {
                resolve();
            }
            this.placeIdToAddToFavourites = null;
        });
    }
private TrackCallAgent() {
        this.trackingService.TrackConversion(this.propertyDetails.eCommerceTracking, true, this.isForSale);
    }
    
}
