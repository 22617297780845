<div class="mortgage-calculator" *ngIf="mortgageEstimate">
    <div class="mortgage-row-flex">
        <pb-textbox class="left-item" [(binding)]="mortgagePrice" numbersOnly="true" placeholder="Property price" caption="Property price" prefix="{{mortgageEstimate.currencySymbol}}" clearIcon="false"></pb-textbox>
        <pb-textbox [(binding)]="mortgageDeposit" numbersOnly="true" placeholder="Deposit" caption="Deposit" prefix="{{mortgageEstimate.currencySymbol}}" clearIcon="false"></pb-textbox>
    </div>

    
    <div class="mortgage-row-flex">
        <pb-textbox class="left-item" [(binding)]="mortgageInterest" placeholder="Annual interest" caption="Interest rate" prefix="%" numbersOnly="true" clearIcon="false"></pb-textbox>
        <pb-dropdown classes = "numberOfBranches" name="RepaymentPeriod" placeholder="Repayment period" caption="Repayment period"  [options]="mortgageRepaymentPeriods" [(selectedValue)]="mortgageRepaymentPeriod"></pb-dropdown>
    </div>

    <div class="mortgage-row-flex">
        <link-button class="left-item" title="Calculate" i18n-title="Calculate|Calculate" beforeIcon="calculate" border="true"   size="small" (click)="PerformGetMortgageEstimateMutation()"></link-button>
        <link-button nofollow = "true" title="Find out how much you can borrow" i18n-title="Find out how much you can borrow|Find out how much you can borrow" beforeIcon="pound" border="false" fullWidth="true" size="small" href="https://www.onedome.com/services/mortgage-passport?utm_source=placebuzz&utm_medium=web&utm_campaign=mortgage_calculator" newWindow="true" externalLink="true"></link-button>
    </div>

    



    <section class="mortgage-circle">
        <div class="outer-circle">
            <div *ngIf="!isEstimatingMortgage" class="inner-circle">
                <div class="mortgage-circle-content">
                    <span class="caption" i18n="Monthly_Repayment|Monthly repayment">Monthly repayment</span>
                    <label class="subtitle3">{{mortgageEstimate.estimatedMonthlyMortgage}}</label>
                </div>
            </div>
            <div *ngIf="isEstimatingMortgage" class="loader-circle">
                <horizontal-loader></horizontal-loader>
            </div>
        </div>
    </section>
    <section *ngIf="mortgageEstimate.isAboveVisibilityThreshold" class="mortgage-actions">
        <p class="caption">{{mortgageEstimate.estimatedMonthlyMortgageInfo}}</p>
    </section>
</div>