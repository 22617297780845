import { Route } from "@angular/router";
import { ContactAgentComponent } from "./contact-agent/contact-agent.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { PropertyDetailsPageComponent } from "./property-details-page/property-details-page.component";
import { PropertyDetailsComponent } from "./property-details/property-details.component";

const propertyDetailsRoutes: Route[] = [
    {
        path: '',
        component: PropertyDetailsPageComponent, 
        children: [
            {
                path: 'contact-agent',
                component: ContactAgentComponent,
                data: { page: 'contact-agent' } 
            },
            {
                path: ':page/:imageId',
                component: ImageViewerComponent,
                data: { page: 'images' } 
            },
            {
                path: ':page',
                component: ImageViewerComponent,
                data: { page: 'images' } 
            },
            {
                path: '',
                component: PropertyDetailsComponent,
                data: { page: 'property-details' } 
            }
        ]
    },
];

export default propertyDetailsRoutes;