import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SocialSharingComponent } from '../../../modules/isolated.components/social-sharing/social-sharing.component';
import { NgIf } from '@angular/common';
import { PbButtonComponent } from '../../../controls/pb-button/pb-button.component';
import { AreaGuide } from '../model/areaguide';
import { RouterLink } from '@angular/router';
import { NavAnchorDirective } from 'src/app/directives/nav-anchor.directive';
import { RatingStarsComponent } from '../rating/rating.stars.component';


@Component({
    selector: 'search-header',
    templateUrl: './search-header.component.html',
    styleUrls: ['./search-header.component.scss'],
    standalone: true,
    imports: [PbButtonComponent, NgIf, SocialSharingComponent, RouterLink, NavAnchorDirective, RatingStarsComponent]
})
export class SearchHeaderComponent {

  @Input() title:string="";
  @Input() subTitle:string="";
  @Input() shareUrl:string;
  @Input() metaTitle:string;
  @Input() metaDescription:string;
  @Input() isSearchSaved:boolean = false;
  @Input() hasCriteriaChanged:boolean = false;
  @Input() isAlertOn:boolean = false;
  @Input() areaGuide: AreaGuide;

  
  @Output() saveClicked: any = new EventEmitter<any>();
  @Output() alertsClicked: any = new EventEmitter<any>();


  onSaveClicked(){
    this.saveClicked.emit(true);
  }

  onAlertsClicked(){
    this.alertsClicked.emit(true);
  }

}
