import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyListingsResult } from 'src/app/services/search.module/model/propertyListingsResult';
import { RatingStarsComponent } from '../rating/rating.stars.component';
import { PbButtonComponent } from 'src/app/controls/pb-button/pb-button.component';
import { Router } from '@angular/router';
import { SafeHtmlPipe } from 'src/app/pipes/safe.html.pipe';

@Component({
  selector: 'area-guide-box',
  standalone: true,
  imports: [
    RatingStarsComponent, PbButtonComponent, NgIf, SafeHtmlPipe
  ],
  template: ` <div class="area-guide">
                <h3 class="small">{{this.propertyListings?.placeSearchCriteria.searchArea}} Rating</h3>
                <rating-stars [rating]="this.propertyListings?.areaGuide.averageScore" [ratingText]="this.propertyListings?.areaGuide.averageScore.toFixed(1)"></rating-stars>
                <div class="at-a-glance" *ngIf="this.propertyListings?.areaGuide.atAGlance" [innerHtml]="this.propertyListings?.areaGuide.atAGlance|safeHtml"></div>
                <div class="buttons">
                  <pb-button *ngIf="this.propertyListings?.areaGuide.atAGlance" buttonStyle="primary"  buttonSize="regular" iconColour="#4a4a4a" caption="View Ratings" (clicked)="OnAreaGuideClicked()"></pb-button>
                  <pb-button leftIcon="star" buttonStyle="primary"  buttonSize="regular" iconColour="#4a4a4a" caption="Rate this area" (clicked)="OnAreaGuideClicked()"></pb-button>
                </div>
              </div>`,
  styleUrl: './area-guide-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaGuideBoxComponent { 
  constructor(
    private router: Router
  ) { }

  @Input() propertyListings: PropertyListingsResult;

  OnAreaGuideClicked(){
    this.router.navigate([this.propertyListings.areaGuide.relativePath]);
  }

}
