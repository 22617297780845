export var requestDetailsForRent = [
    {
        "key": "Please send me more details",
        "value": "Please send me more details"
    },
    {
        "key": "What are the fees and deposits?",
        "value": "What are the fees and deposits?"
    },
    {
        "key": "When is it available from?",
        "value": "When is it available from?"
    },
    {
        "key": "Do you accept pets?",
        "value": "Do you accept pets?"
    },
    {
        "key": "Do you have similar properties available?",
        "value": "Do you have similar properties available?"
    }
];

export var requestDetailsForSale = [
    {
        "key": "Could someone call me back?",
        "value": "Could someone call me back?"
    },
    {
        "key": "Is this property open to offers?",
        "value": "Is this property open to offers?"
    },
    {
        "key": "Please send me the Home Report.",
        "value": "Please send me the Home Report."
    },
    {
        "key": "Do you have similar properties available?",
        "value": "Do you have similar properties available?"
    },
    {
        "key": "Please send me some more details.",
        "value": "Please send me some more details."
    }
];

export var employmentStatuses = [
    {
        "key": 0,
        "value": "Please Select"
    },
    {
        "key": 1,
        "value": "Employed"
    },
    {
        "key": 2,
        "value": "Self Employed"
    },
    {
        "key": 3,
        "value": "Housing Benefit (part or full)"
    },
    {
        "key": 4,
        "value": "Unemployed (other income)"
    },
    {
        "key": 5,
        "value": "Student"
    },
    {
        "key": 6,
        "value": "Retired"
    }];

