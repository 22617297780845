import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { GlobalErrorHandler } from './services/global.error.handler/error.handler.service';
import { provideAnimations } from '@angular/platform-browser/animations';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

export const appConfig: ApplicationConfig = {
  providers: 
    [
      provideRouter(routes, withInMemoryScrolling(scrollConfig)), 
      provideClientHydration(withHttpTransferCacheOptions({includePostRequests: true,})), 
      provideHttpClient(
        withFetch()
      ),
      provideAnimations(),
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler
      }
    ]
};
