import { Injectable } from "@angular/core";
import { adDefinitions} from "./adunits"
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { LoggingService } from "../logging.module/logging.service";
import { BreakpointService } from "../breakpoint.module/breakpoint.service";

@Injectable({providedIn: 'root'})
export class AdsService {

    constructor(
        private platformHelpersService: PlatformHelpersService,
        private loggingService: LoggingService,
        private breakpointService: BreakpointService
    ){ }

    registerZone(divId:string){
        if(this.platformHelpersService.IsBrowserPlatform && !this.platformHelpersService.IsAutomatedTestUserAgent){
            this.loggingService.LogToDevConsole("Fuse Tag Register Zone for Div: " + divId);

            const fusetag = this.platformHelpersService.Window.fusetag || (this.platformHelpersService.Window.fusetag = { que: [] });
            this.platformHelpersService.Window.fusetag.que.push(function() {
                fusetag.registerZone(divId);
            });
        }
    }

    registerAllZones(){
        if(this.platformHelpersService.IsBrowserPlatform && !this.platformHelpersService.IsAutomatedTestUserAgent){
            this.loggingService.LogToDevConsole("Fuse Tag Register All called");
            const fusetag = this.platformHelpersService.Window.fusetag || (this.platformHelpersService.Window.fusetag = { que: [] });
            this.platformHelpersService.Window.fusetag.que.push(function() {
                fusetag.registerAll();
            });
        }
    }


    destroyZone(divId:string){
        if(this.platformHelpersService.IsBrowserPlatform && !this.platformHelpersService.IsAutomatedTestUserAgent){
            this.loggingService.LogToDevConsole("Fuse Tag Destroy Zone for Div: " + divId);
            const fusetag = this.platformHelpersService.Window.fusetag || (this.platformHelpersService.Window.fusetag = { que: [] });
            this.platformHelpersService.Window.fusetag.que.push(function() {
                fusetag.destroyZone(divId);
            });
        }
    }

    getAdFuseId(adPosition:string): string {
        var deviceType = this.getDeviceType();
        var adDefinition = adDefinitions.find(x => x.device.indexOf(deviceType)>-1 && x.adPosition==adPosition);
        return adDefinition.fuseId;
    }

    initializeFuseTag(page:string){
        if(this.platformHelpersService.IsBrowserPlatform && !this.platformHelpersService.IsAutomatedTestUserAgent){
            const fusetag = this.platformHelpersService.Window.fusetag || (this.platformHelpersService.Window.fusetag = { que: [] });

            var fuseIds: string[];

            var deviceType = this.getDeviceType();

            var adDefinitionsForPage = adDefinitions.filter(x => x.page == page && x.device.indexOf(deviceType) > -1);
            fuseIds = adDefinitionsForPage.map(x => x.fuseId);

            this.loggingService.LogToDevConsole("Fuse InitializeFuseTag called for " + page + " with fuseIds: " + fuseIds.join(", "));

            fusetag.que.push(function() {
                fusetag.pageInit({
                    blockingFuseIds: fuseIds,   
                    pageTargets: [
                        {
                            'key': 'section','value': [page],
                        },
                    ]
                });
            });
        }
    }


    private getDeviceType() {
        var adBreakpoint = this.breakpointService.getBreakpoint();
        var deviceType = "desktop";
        
        if (adBreakpoint == 'mobile' || adBreakpoint == 'tablet') {
            deviceType = "mobile";
        }
        return deviceType;
    }
}