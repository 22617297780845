<nav class="pagination" *ngIf="items && items.length>0">
    <div class="previous">
        <a  (click)="onPageChanged($event, 'Previous')" [href]="_items[0].url|safeUrl" [attr.disabled]="_items[0].isEnabled?null:''" rel="{{_items[0].rel}}">
            <svg class="svg-icon" style="width:24px;height:24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#4a4a4a"><path d="m13.72 11.966 3.884-3.884a1.367 1.367 0 0 0 0-1.942l-2.736-2.735a1.367 1.367 0 0 0-1.942 0L8.14 8.328l-2.735 2.735a1.367 1.367 0 0 0 0 1.942L8.14 15.74l4.855 4.856a1.368 1.368 0 0 0 1.942 0l2.735-2.736a1.367 1.367 0 0 0 0-1.942l-3.952-3.952Z"></path></svg>
            <span>Back</span>
        </a>
    </div>

    <div class="pages">
        <ol>
            <li *ngFor="let item of items; let i = index" [class.selected]="item.isSelected">
                <a (click)="onPageChanged($event, item)" [class.selected]="item.isSelected"  [href]="item.url|safeUrl" [attr.disabled]="item.isEnabled?null:''" rel="{{item.rel}}">
                    <label>{{item.value}}</label>
                </a>
            </li>
        </ol>
    </div>

    <div class="next">
        <a (click)="onPageChanged($event, 'Next')" [href]="_items[_items.length-1].url|safeUrl" [attr.disabled]="_items[_items.length-1].isEnabled?null:''" rel="{{_items[_items.length-1].rel}}">
            <span>Next</span>
            <svg class="svg-icon" style="width:24px;height:24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#4a4a4a"><path d="m17.615 10.987-2.756-2.756-4.824-4.823a1.378 1.378 0 0 0-1.957 0L5.322 6.164a1.378 1.378 0 0 0 .11 1.888l3.873 3.914-3.873 3.913a1.377 1.377 0 0 0 0 1.957l2.757 2.756a1.378 1.378 0 0 0 1.957 0l4.781-4.96 2.757-2.757a1.378 1.378 0 0 0-.07-1.888Z"></path></svg>
        </a>
    </div>

</nav>