import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QUERY_HYDRATION_KEYS } from 'src/app/infrastructure/query.hydration.keys';
import { GqlQueryBuilder } from '../graphql.module/graphql.query.builder';
import { PropertyDetails } from 'src/app/pages/property-details/property-details-page/model';
import { SponsoredListings } from '../campaigns.module/model';
import { GraphQLService } from '../graphql.module/graphql.service';
import { CreatePropertyListingDetailsQueryPart } from 'src/app/graphql/queries/property.listing.details.query';
import { CreatePropertySimilarListingsQueryPart } from 'src/app/graphql/queries/similar.listings.query';
import { SponsoredListingsByListingIdQuery, SponsoredListingsByListingIdQueryArgs } from 'src/app/graphql/queries/sponsored.listings.by.listing.id.query';
import { CreateGqlQueryArguments } from 'src/app/graphql/create.query';

@Injectable({
  providedIn: 'root'
})
export class PropertyDetailsService {
  private propertyDetailsSubject = new BehaviorSubject<PropertyDetails | null>(null);

  constructor(
    private gqlService: GraphQLService,
  ) { }

  getPropertyDetailsObservable(): Observable<PropertyDetails | null> {
    return this.propertyDetailsSubject.asObservable();
  }

  setPropertyDetails(details: PropertyDetails): void {
    this.propertyDetailsSubject.next(details);
  }

  getAndStorePropertyDetails(listingId: string, mortgageEstimateInput: any, campaignId: number): Promise<PropertyDetails> {
    // Existing logic to fetch property details
    var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.PropertyListingDetailsQuery);
    queryBuilder.BuildMultiPartQuery([
      CreatePropertyListingDetailsQueryPart(listingId, null, campaignId)
    ]);

    return new Promise(resolve => {
      this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.PropertyListingDetailsQuery, queryBuilder.query, queryBuilder.variables, this.getAndStorePropertyDetails, this, true).then(response => {
        var result: any = {};
        if (this.gqlService.ProcessResponse(result, response)) {
            this.setPropertyDetails(result.propertyListingDetails); // Update the BehaviorSubject
            resolve(result.propertyListingDetails);
        }
      });
    });
  }

  getSimilarListings(listingId:string):Promise<any>{
    var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.SimilarListingsQuery);

    queryBuilder.BuildMultiPartQuery([
      CreatePropertySimilarListingsQueryPart(listingId)
    ]);

    return new Promise(resolve => {
      this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.SimilarListingsQuery, queryBuilder.query, queryBuilder.variables,
        () => this.getSimilarListings(listingId), this, false, null, true).then(response => {
        var result = {};
        
        if (this.gqlService.ProcessResponse(result, response, true)) {
          resolve((<any>result).propertySimilarListings);
        }
        resolve(null);
      });
    });
  }


  getSponsoredListings(listingId: number): Promise<SponsoredListings> {

    var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.SponsoredListingsByListingIdQuery);

    queryBuilder.BuildMultiPartQuery([{
        queryConstant: SponsoredListingsByListingIdQuery,
        arguments: CreateGqlQueryArguments(SponsoredListingsByListingIdQueryArgs, [listingId.toString()])
    }]);
    
    return new Promise(resolve => {
      this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.SponsoredListingsQuery, queryBuilder.query, queryBuilder.variables, ()=> this.getSponsoredListings(listingId), this, false, null, true).then(response => {
          var result: any = {}
          if (this.gqlService.ProcessResponse(result, response)) {
              resolve(result);
          }
          resolve(null);
      });
    });
  }

  
}