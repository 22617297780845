import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'property-listing-image',
    templateUrl: './property-listing-image.component.html',
    styleUrls: ['./property-listing-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class PropertyListingImageComponent implements OnInit {

  constructor() { }

  private _src:string;

  @Input() set src (value:string){
    this._src = value;
    if (value){
      this.checkImage = true;
    }
  }
  @Input() width:number = 480;
  @Input() height:number = 365;
  checkImage:boolean = false;

  get src():string{
    return this._src;
  }

  @Input() alt:string;

  ngOnInit(): void {
  }

  
  get srcset(){
    if (this.src){

      var loRes = this.src.replace("/photo/", "/c" + this.width + "x" + this.height + "/photo/");
      var hiRes = this.src.replace("/photo/", "/c" + this.width*2 + "x" + this.height*2 + "/photo/");
  
      return loRes + " 1x, " + hiRes + " 2x";
    }
    return "";
  }

  get srcsetWebp(){
    if (this.src){

      var loRes = this.src.replace("/photo/", "/c" + this.width + "x" + this.height + "/photo/").replace(".jpg", ".webp");
      var hiRes = this.src.replace("/photo/", "/c" + this.width*2 + "x" + this.height*2 + "/photo/").replace(".jpg", ".webp");

      return loRes + " 1x, " + hiRes + " 2x";
    }
    return "";
  }

  replaceWebpExtension(imageSource:string){
    return imageSource.replace(".jpg", ".webp");
  }

  get imageSource(){
    if (this.src){
      if (this.src.indexOf('svg+xml', 0)==-1){
        return this.src.replace("/photo/", "/c" + this.width + "x" + this.height + "/photo/");
      }
      else{
        return this.src;
      }
      
    }
    return "";
  }

  errorState:boolean = false;


  setNoImage(){
    if (this.src && this.checkImage){
      this.errorState = true;
    }
  }

  


}

