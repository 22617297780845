import { Component, Input, ContentChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MenuItemComponentBase } from '../../component.base.classes/menuitem.component.base';
import { RouterLink } from '@angular/router';
import { SafeUrlPipe } from '../../../pipes/safe.url.pipe';
import { PbIconComponent } from '../../../controls/pb-icon/pb-icon.component';
import { ActionTrackingDirective } from '../../../directives/action-tracking.directive';
import { NgIf } from '@angular/common';


@Component({
    selector: 'icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    standalone: true,
    imports: [NgIf, ActionTrackingDirective, PbIconComponent, RouterLink, SafeUrlPipe]
})
export class IconButtonComponent extends MenuItemComponentBase {
    @Input() iconClasses: string;
    @Input() fontIconClass: string;
    @Input() href: string = "javascript:void(0);";
    @Input() target: string = "";
    @Input() routerLink: string = "";
    @Input() actionTrackingLabel: string;
    @Input() icon:string;
    @ContentChild(TemplateRef) _templateRef;
    

    get classes(): string {
        //return this.fontIconClass + " " + (this.iconClasses || " ");
        return (this.iconClasses || " ");
    }

    constructor() {
        super();
    }

    DoOnItemClicked(): void {

    }
}
