/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from './environments/environment';

Sentry.init({
  dsn: "https://1c6bb8236ff998a8ead0f2230445edd3@o4506803436126208.ingest.us.sentry.io/4506984502198272",
  environment: environment.stack,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  ignoreErrors: [
    /pubads_impl/i,  // Ignore errors containing 'pubads_impl' (case insensitive)
    /pagead/i,
    /ampproject/i,
    /extractFilteredSchemaValuesFromMicroData/i, // Ignore errors containing 'googletagmanager' (case insensitive)
    /adperformance/i,
    /strikeforce/i,
    /ftpagefold/i,
    /pagead\/managed\/js\/gpt\/.*\/pubads_impl/i,
    /IntersectionObserver/i,
    /NS_ERROR_NOT_INITIALIZED/i,
  ],
  attachStacktrace: true,
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^(https?:\/\/)?(.+\.)?placebuzz\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.001, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
