import { Component, OnInit, OnDestroy, Renderer2, Inject, AfterViewInit } from "@angular/core";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShowLoginMessageData } from "src/app/infrastructure/message.data.types";
import { Subscription } from "rxjs";
import { PlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { PropertyListingsResult } from "src/app/services/search.module/model/propertyListingsResult";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { PAGE_TRACKING_NAMES } from "src/app/infrastructure/tracking.constants";
import { CopyObject, RemoveSubscriptions} from "src/app/infrastructure/object.helpers";
import { PropertyListing } from "src/app/infrastructure/model/propertyListing";
//import { Rating } from "src/app/modules/isolated.components/rating/model";
import { LoggingService } from "src/app/services/logging.module/logging.service";
import { DOCUMENT, NgIf } from "@angular/common";
import { filter } from "rxjs/operators";
import { AdsBasePageComponent } from "src/app/base-pages/ads-base-page/ads-base-page.component";
import { SearchMapComponent } from "../search-map/search-map.component";
import { SearchListComponent } from "../search-list/search-list.component";
import { ResultsHeaderComponent } from "../results-header/results-header.component";
import { SearchHeaderComponent } from "../search-header/search-header.component";
import { PbSearchBarComponent } from "../../../components/search-bar/search-bar.component";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { PlacebuzzSearchService } from "src/app/services/search.module/placebuzz.search.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { AuthorizationService } from "src/app/services/authorization.module/authorization.service";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { HeadService } from "src/app/services/head.module/head.service";
import { EnquiriesService } from "src/app/services/enquiries.service.module/enquiries.service";
import { MetaTagsServiceService } from "src/app/services/meta.tags.module/meta.tags.service.service";
import { ModelCachingService } from "src/app/services/model.caching.module/model.caching.service";
import { AdsService } from "src/app/services/ads.service.module/ads.service";
import { BreakpointService } from "src/app/services/breakpoint.module/breakpoint.service";
import { AverageAskingPrice } from "src/app/services/search.module/model/averageAskingPrice";
import { AdUnitComponent } from "src/app/components/ad-unit/ad-unit.component";
import { DoubleClickAdsService } from "src/app/services/double.click.ads.module/double.click.ads.service";


@Component({
    selector: 'search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    standalone: true,
    providers: [],
    imports: [PbSearchBarComponent, NgIf, SearchHeaderComponent, ResultsHeaderComponent, SearchListComponent, SearchMapComponent, AdUnitComponent]
})




export class SearchPageComponent extends AdsBasePageComponent implements OnInit, OnDestroy {
    averagePricesComponentModel: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private messenger: Messenger,
        private applicationStateService: ApplicationStateService,
        private urlHelperService: UrlHelpersService,
        private placebuzzSearchService: PlacebuzzSearchService,
        private platformService: PlatformHelpersService,
        private authorizationService: AuthorizationService,
        private trackingService: TrackingService,
        private headService: HeadService,
        private enquiriesService: EnquiriesService,
        loggingService: LoggingService,
        private metaService: MetaTagsServiceService,
        private modelCachingService: ModelCachingService,
        adsService: AdsService,
        breakpointService: BreakpointService,
        router: Router,
        private renderer: Renderer2,
        doubleClickAdsService: DoubleClickAdsService,



        @Inject(DOCUMENT) private _document: Document
    ) { 

        super(adsService, platformService, loggingService, breakpointService ,router, doubleClickAdsService)
        this.adsPage = "search_results"
        this.registerSubscriptions();

    }
   
//#region properties
    
    private subscriptions: Array<Subscription> = new Array<Subscription>();
    
    private placeIdToAddToFavourites: number;

    showAds:boolean = true;
    saleOrRent: string;
    placeSearchCriteria: PlaceSearchCriteria;
    propertyListings: PropertyListingsResult;
    listingMode: string = "list";
    //rating: Rating;
    areaGuideLink: string;
    areaGuideRel: string;
    showTestAds:boolean;
    

    searchCriteriaChanged: boolean = false;
   


    
    registerSubscriptions(){
        this.subscriptions.push(this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
              this.loggingService.LogToDevConsole("<search-results>Navigation End</search-results>");  
              this.OnActivatedRouteParamsChanged(null);
        }));
        
        this.subscriptions.push(
            this.activatedRoute.firstChild.data.subscribe(data =>{        
            if (data[0] && data[0].page){ 
                if (data[0].page!= this.listingMode){
                    this.loggingService.LogToDevConsole("<search-results>Found page in Array in Data</search-results>");
                    this.listingMode = data[0].page;} 
                }
            else {
                if (data["page"]!= this.listingMode){ 
                    this.loggingService.LogToDevConsole("<search-results>Found page in Data</search-results>");
                    this.listingMode = data["page"];
                }
            }
            })
        );
    }

    get isForSale(): boolean {
        return this.saleOrRent === "sale";
    }


    
//#endregion


//#region Page Events

override ngOnInit(): void {
    super.ngOnInit();
    this.loggingService.LogToDevConsole("<search-results> OnInit </search-results>");
    this.showTestAds = this.platformService.IsAutomatedTestUserAgent || this.platformService.IsLocalhost;
}

 

override ngOnDestroy(): void {
    this.loggingService.LogToDevConsole("<search-results>>Destroying</search-results>");
    super.ngOnDestroy();
    RemoveSubscriptions(this.subscriptions);
}

private OnActivatedRouteParamsChanged(q: any) {

    this.loggingService.LogToDevConsole("<search-results>Route changed</search-results>");

    const state = this.getState();
    
    if (state){
        this.searchCriteriaChanged = state.hasCriteriaChanged;
    }

    this.placeSearchCriteria = null;
    this.propertyListings = null;

    if (state) {

        if (state.mode){
            this.listingMode = state.mode;
        }
        else{
            this.listingMode = "list";
        }
        
        const psc = CopyObject(state.placeSearchCriteria); 
        if (psc){
            this.loggingService.LogToDevConsole("<search-results>Found placeSearchCriteria in state</search-results>");
            this.placeSearchCriteria = psc;
        }

        const pl = state.listings;

        if (pl){
            this.loggingService.LogToDevConsole("<search-results>Found listings in state</search-results>");
            this.propertyListings = pl;
            this.ProcessListings();
            return;
        }
    }

    if (this.platformService.IsBrowserPlatform){
        let cachedResults = this.modelCachingService.getModel(this.router.url);

        if (cachedResults){
            this.loggingService.LogToDevConsole("<search-results>Found cached results</search-results>");
            this.propertyListings = cachedResults.model;
            this.listingMode = cachedResults.mode;
            this.placeSearchCriteria = this.propertyListings.placeSearchCriteria;
        }
    }
    

    if (!this.placeSearchCriteria) {
        this.loggingService.LogToDevConsole("<search-results>No placeSearchCriteria in state</search-results>");
        this.saleOrRent = this.activatedRoute.snapshot.parent.url[0].path.toLowerCase() === "property-for-sale" ? "sale" : "rent";
        this.placeIdToAddToFavourites = parseInt(this.activatedRoute.snapshot.queryParams["addFavouritePlaceId"]);
        this.placeSearchCriteria = this.urlHelperService.LocationUrlToPlaceSearchCriteria();

        //check if latitude or longitude are empty and if so, get the user's location
        if ((this.activatedRoute.parent.snapshot.url[1] && this.activatedRoute.parent.snapshot.url[1].path=="search") 
                && (!this.placeSearchCriteria.latitude || !this.placeSearchCriteria.longitude)) {
            this.router.navigate(["/search"]);
            return;
        }
        
        this.RunSearch(this.placeSearchCriteria);
        
    }
    else {
        this.ProcessListings();
    }
    
    this.LoadDynamicAds();

    
}

onPageClicked(url: string) {
    this.placeSearchCriteria = null;
    this.propertyListings = null;

    var placeSearchCriteria = this.urlHelperService.UrlToPlaceSearchCriteria(url);
    this.RunSearch(placeSearchCriteria);
}

OnSearchHereClicked(placeSearchCriteria: PlaceSearchCriteria) {
    this.RunSearch(placeSearchCriteria);
}

OnSaveClicked() {
    this.DoSave();
}

onAlertsClicked(){
    this.DoSave(true);
}

onSearchFiltersChanged(){
    //var shouldSaveSearch = this.authorizationService.isLoggedIn && this.placeSearchCriteria.placeSearchCriteriaId !== undefined && this.placeSearchCriteria.placeSearchCriteriaId !== null && this.placeSearchCriteria.placeSearchCriteriaId !== 0;
    this.searchCriteriaChanged = true;
    this.RunSearch(this.placeSearchCriteria, true);
}

onSortChanged(sortBy:string){
    this.placeSearchCriteria.sortType = sortBy;
    this.onSearchFiltersChanged();
}

onPageChanged(page: any) {
    this.placeSearchCriteria.page = page;
    this.RunSearch(this.placeSearchCriteria);

}

//#endregion

private ProcessListings() {

    var setNoIndex: boolean = false;
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (Object.keys(queryParams).length > 0) {
        setNoIndex = true;
    }

    

    this.metaService.CreateSearchResultsMetaTags(this.propertyListings, this.headService, this.listingMode, setNoIndex);
    
    if (this.propertyListings.breadcrumbListJsonLd) {
        this.InsertSchema(this.propertyListings.breadcrumbListJsonLd);
    }
    
    this.TrackECommerce();
    
    if (this.propertyListings.areaGuide) {
        //this.rating = { max: 5, rate: parseFloat(this.propertyListings.areaGuide.averageScore.toFixed(1)) };
        this.areaGuideLink = this.propertyListings.areaGuide.relativePath;
        this.areaGuideRel = this.propertyListings.areaGuide.linkRel;
    }

    this.UpdateFavourites();
    this.UpdateEnquiries();
    this.AddPlaceIdToFavourites(this.propertyListings.listings);
}

private RunSearch(placeSearchCriteria: PlaceSearchCriteria, updateUrl:boolean = true, saveSearch:boolean = false, setAlertsOn:boolean = false){
    
    if (saveSearch){
        this.loggingService.LogToDevConsole("<search-results>Running Save Search Mutation</search-results>");

        if (setAlertsOn){
            this.placeSearchCriteria.hasAlerts = true;
        }

        this.placebuzzSearchService.PerformSaveSearchMutation(this.placeSearchCriteria, this.RunSearch, this).
        then(response => {
            this.placeSearchCriteria = response;
            this.searchCriteriaChanged = false;
            this.placebuzzSearchService.PerformPropertyListingsSearch(this.placeSearchCriteria, this.RunSearch,null,  this.listingMode=='map').then(response => {
                this.placeSearchCriteria = response.placeSearchCriteria;
                this.propertyListings = response;
                this.UpdateUrl(this.placeSearchCriteria, this.listingMode, updateUrl);
                this.ProcessListings();
            });
        });
    }
    else{
        this.loggingService.LogToDevConsole("<search-results>Running Search Query</search-results>");
        this.placebuzzSearchService.PerformPropertyListingsSearch(placeSearchCriteria, this.RunSearch,null,  this.listingMode=='map').then(response => {
            this.placeSearchCriteria = response.placeSearchCriteria;
            this.propertyListings = response;

            var flagAsCriteriaChanged = false;

            if (this.placeSearchCriteria.placeSearchCriteriaId && this.placeSearchCriteria.placeSearchCriteriaId>0 && this.searchCriteriaChanged){
                flagAsCriteriaChanged = true;
            }

            this.UpdateUrl(this.placeSearchCriteria, this.listingMode, updateUrl, flagAsCriteriaChanged);
            this.ProcessListings();
        });
    }
}

private UpdateUrl(placeSearchCriteria: PlaceSearchCriteria, listingMode:string = "list", navigateToUrl:boolean = true, hasCriteriaChanged:boolean = false) {
    if (this.platformService.IsBrowserPlatform){
        
        var newPath = placeSearchCriteria.path;
        
        if (listingMode =='map'){
            if (newPath.indexOf("?") > -1){
                newPath = newPath.replace("?","/map?");
            }
            else{
                newPath = newPath + "/map";
            }           
        }

        var newUrl = this.urlHelperService.PlaceSearchCriteriaToUrl(this.placeSearchCriteria, listingMode);
        
        if (this.platformService.IsBrowserPlatform){
            this.loggingService.LogToDevConsole("<search-results>Storing results in cache</search-results>");
            this.modelCachingService.saveModel(this.urlHelperService.GetUrlStringFromParsedUrl(newUrl), this.propertyListings, this.listingMode);
        }
        
        
        if (navigateToUrl){
            this.loggingService.LogToDevConsole("<search-results-url>Updating URL to : " + newUrl.path + "</search-results>");
            this.router.navigate(newUrl.path, { queryParams: newUrl.queryObject, state:{placeSearchCriteria: this.placeSearchCriteria, listings: this.propertyListings, mode: this.listingMode, page: "search-results", hasCriteriaChanged: hasCriteriaChanged} });
         }
        
    }
}

    private AddPlaceIdToFavourites(listings: PropertyListing[]): Promise<void> {
        return new Promise<void>(resolve => {
            if (this.placeIdToAddToFavourites && listings && this.applicationStateService.me) {
                var listingToAdd: PropertyListing = listings.find(l => l.placeId === this.placeIdToAddToFavourites);
                this.placeIdToAddToFavourites = null;
                if (listingToAdd) {
                    this.applicationStateService.AddToFavourites(listingToAdd.placeId).then(result => {
                        listingToAdd.isFavourite = result;
                        this.urlHelperService.RemoveQueryFromLocation("addFavouritePlaceId");
                        resolve();
                    });
                }
            }
            else {
                resolve();
            }
        });
    }

    getState(): any {
        const navigation = this.router.getCurrentNavigation();
        const state = navigation?.extras.state as any;
        if (state){
            return state;
        }
        else{
            return null;
        }
    }
    
    SetPageTracking(): void {
        this.trackingService.SetCurrentPageName(this.isForSale ? PAGE_TRACKING_NAMES.SearchForSale : PAGE_TRACKING_NAMES.SearchForRent);
    }

   

    private InsertSchema(jsonData, className = 'structured-data-website'): void {
    
        if (this._document.getElementById(className)){
            return;
            
        }
    
        let script;
        script = this._document.createElement('script');
        script.setAttribute('id', className);
        script.type = "application/ld+json";
        script.text = jsonData;
        this._document.head.insertBefore(script, this._document.head.firstChild);
    }

    private LoadDynamicAds() {
        if (this.applicationStateService.isMobileApp){
            this.showAds = false;
        }
    }

    private TrackECommerce(): void {
        var listings = this.propertyListings.listings.filter(l => !l.adDefinition);
        for (var i = 0; i < listings.length; i++) {
            listings[i].eCommerceTracking.position = this.propertyListings.pagination.pageFirstItemNumber + i;
            listings[i].eCommerceTracking.clientListForActions = this.trackingService.GetCurrentPageName();
            listings[i].eCommerceTracking.clientListForImpressions = this.trackingService.GetCurrentPageName();
        }
        this.trackingService.TrackECommerceImpressions(listings.map(l => l.eCommerceTracking));
        this.trackingService.SetCurrentDetailsReferrer(this.trackingService.GetCurrentPageName());
    }

    private UpdateFavourites(): void {
        if (this.applicationStateService.me && this.applicationStateService.me.favourites) {
            this.propertyListings.listings.map(l => {
                var place = this.applicationStateService.me.favourites.find(p => p.placeId === l.placeId);
                l.isFavourite = !!place;
            });

        }
    }

    private UpdateEnquiries(): void {
        if (this.enquiriesService.shouldProcessEnquiries) {
            this.propertyListings.listings.map(l => {
                var enquiry = this.enquiriesService.GetEnquiryForPlaceId(l.placeId);
                if (enquiry) {
                    l.enquiry = enquiry;
                }
            });
        }
    }

    SelectListingMode(mode: string) {
        this.listingMode = mode;
        this.RunSearch(this.placeSearchCriteria, true, false);
    }

    showHideFooter() {
        const targetElement = document.getElementsByClassName('app-footer-wrapper')[0];
        if (targetElement) {
            if (this.listingMode=='map')
                this.renderer.addClass(targetElement, 'app-footer-wrapper-collapsed');
            else{
                this.renderer.removeClass(targetElement, 'app-footer-wrapper-collapsed');
            }
        }
    }

    private DoSave(alerts:boolean = false) {
        if (!this.ShowSignUpIfLoggedOut()) {
            if (this.placeSearchCriteria.placeSearchCriteriaId) {
                this.RunSearch(this.placeSearchCriteria, false, true, alerts);
            }
            else {
                this.RunSearch(this.placeSearchCriteria, true, true, alerts);
            }
        }
    }

    get showStickyBanner (){
        return this.platformHelpersService.adsProvider === 'tude' || this.platformHelpersService.adsProvider === 'admanager';
    }

    private ShowSignUpIfLoggedOut(): boolean {
        if (!this.authorizationService.isLoggedIn) {
            var loginMessageData: ShowLoginMessageData = {
                showOverlay: true,
                isRegistration: true,
                shouldSaveSearch: true,
                shouldTurnOnAlerts: true,
                registerText: [
                    "Get new properties straight to your inbox",
                    "Sign up to",
                    "get",
                    "alerts when properties are listed anywhere online",
                ]
            };
            this.messenger.Send({
                messageType: MESSAGE_TYPES.SHOW_LOGIN,
                messageData: loginMessageData
            });
            return true;
        }
        return false;
    }
}
