<div class="mapx-container">
    <pb-button id="list-view" (clicked)="onClickedList()"  leftIcon="list" caption="List" buttonSize="small" extraClasses="list-button"></pb-button> 
    <pb-button (clicked)="OnClickedSearchHere()" caption="Search here" buttonSize="small" [isDisabled]="this.isSearching" extraClasses="searchHereButton"></pb-button> 
    
    <div #map id="map"></div>
    
    <section class="property-details" *ngIf="isPropertyDetailsVisible">
        <a class="close-details" (click)="onClickedClosePropertyDetails()"><pb-icon name="cross"></pb-icon></a>
        <a class="property-listing-map" [href]="getListingUrl(selectedPropertyListing)|safeUrl" nav-anchor [new-tab]="selectedPropertyListing.isSponsored" [server-side]="selectedPropertyListing.isSponsored">
            <pb-image class="image-container" [src]="this.selectedPropertyListing?.photos[0]" imageHeight="88" imageWidth="132" [lazyLoad]="true"></pb-image>
            <div class="info-container">
                <div class="price">
                    <h6 title="{{selectedPropertyListing.subTitle}}">{{selectedPropertyListing.subTitle}}</h6>
                    <span class="sub-title">{{selectedPropertyListing.title}}</span>
                    <span class="main-price">{{selectedPropertyListing.price}}</span>
                    <span class="price-modifier">{{selectedPropertyListing.priceModifier}}</span>
                    <span *ngIf="selectedPropertyListing.weeklyPrice && (selectedPropertyListing.weeklyPrice.length > 0)" class="main-price">
                        &#124;&nbsp;{{selectedPropertyListing.weeklyPrice}}
                    </span>
                    <span *ngIf="selectedPropertyListing.weeklyPrice && (selectedPropertyListing.weeklyPrice.length > 0)" class="price-modifier">
                        per week
                    </span>
                </div>
            </div>
        </a>
    </section>
</div>