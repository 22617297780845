import { KeyValuePair } from "src/app/infrastructure/types";
import { GqlError } from "src/app/services/graphql.module/model";

export const CONTACT_AGENT_MODES = {
    CONTACT: "contact_agent",
    Arrange_Viewing_Step1: "arrange_viewing_step1",
    Arrange_Viewing_Step2: "arrange_viewing_step2",
    Request_Details_Step1: "request_details_step1",
    Request_Details_Step2: "request_details_step2",
    Mortgage_Quote: "mortgage_quote"
}

export interface PriceRange {
    title: string,
    min: number,
    max: number
    key: number
}

export interface TitleValue {
    title: string,
    value: number
}

export class ForSaleData {
    aboutMe: Array<KeyValuePair<number, string>>;
    financialDetails: Array<KeyValuePair<number, string>>;
    purchaseType: Array<KeyValuePair<number, string>>;

}

export class ContactAgentData {
    forSaleData: ForSaleData;
    hasShareMyDetailsWithPartner: boolean;
    shareMyDetailsWithPartnerText: string;
    propertyViewingDates: Array<KeyValuePair<string, string>>;
    propertyViewingTimes: Array<KeyValuePair<string, string>>;
    requestDetailsForRent: Array<KeyValuePair<string, string>>;
    requestDetailsForSale: Array<KeyValuePair<string, string>>;
}

export class ContactAgent {
    contactAgentData: ContactAgentData;
    employmentStatuses: Array<KeyValuePair<number, string>>;
}

export interface MortgageQuoteData {
    bestToCallTimes: Array<KeyValuePair<string, string>>;
    buyerType: Array<KeyValuePair<number, string>>;
    purchaseType: Array<KeyValuePair<number, string>>;
}

export class ContactAgentMutationResult {
    errors: Array<GqlError>;
    isExistingUser: boolean;
    isValid: boolean;
    existingUserMessage: string;
    existingUserEmailOrTelephone: string;
    price: number;
    token: string;
}

export interface ContactAgentInput {
    listingId: number;
    campaignId: number;
    propertyViewingDate: string,
    propertyViewingTime: string,
    name: string, email: string,
    telephone: string,
    postcode: string,
    listingTypeId: number,
    message: string,
    employmentStatusId: number,
    purchaseTypeId: number,
    financialDetailsId: number,
    aboutMeId: number,
    requestDetails: Array<string>,
    hasShareMyDetailsWithPartnerRequest: boolean,
    mortgageQuoteInput:any
}
