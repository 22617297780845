import { Component, EventEmitter, Input, NgModule, Output } from "@angular/core";
import { PaginationItemLink } from "src/app/infrastructure/model/pagination";
import { ActionAndContext } from "src/app/infrastructure/actionAndContext";
import { NgIf, NgFor } from "@angular/common";
import { SafeUrlPipe } from "../../pipes/safe.url.pipe";

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, SafeUrlPipe]
})
export class PaginationComponent {
    _items: Array<PaginationItemLink>;
    selectedPage: string;
    @Input() executeBeforeNavigation: ActionAndContext<void>;
    @Input() enablePageChangedEvent: boolean = false;
    @Input() totalPages: number;
    @Input() mapsMode: boolean = false;
    @Input() set items(value: Array<PaginationItemLink>) {
        this._items = value;
        if (value && value.length > 0) {
            var selectedPage = this._items.find(p => p.isSelected);
            if (selectedPage) {
                this.selectedPage = selectedPage.value;
            }
        }
    }
    @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();

    get items(): Array<PaginationItemLink> {
        return this._items.filter(item=>item.value!="Previous" && item.value!="Next");
    }

    constructor() { }

    onPageChanged(event:Event, page: any) {
        if (this.enablePageChangedEvent){
            event.preventDefault();
            event.stopPropagation();

            if (page.pageNumber){
                page = page.pageNumber;
            }

            if (page=="Previous"){
                page = Number(this.selectedPage) - 1;
            }

            if (page=="Next"){
                page = Number(this.selectedPage) + 1;
            }

            this.pageChanged.emit(page);
        }
    }
}

