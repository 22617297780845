import { Component, OnDestroy, OnInit } from "@angular/core";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShowLoginMessageData, MenuButtonPerformActionMessageData, UpdateBadgeCountMessageData, MeStateChangedMessageData } from "src/app/infrastructure/message.data.types";
import { Menu, MenuItem, SubMenuItemTypes } from "src/app/services/application.resources.module/model";
import { CopyObject } from "src/app/infrastructure/object.helpers";
import { UserCampaign } from "src/app/infrastructure/model/userCampaign";
import { NavBarIconButtonsComponent} from "../nav-bar-icon-buttons/nav-bar-icon-buttons.component";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { AuthorizationService } from "src/app/services/authorization.module/authorization.service";

import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { ApplicationResourcesService } from "src/app/services/application.resources.module/application.resources.service";
import { EnquiriesService } from "src/app/services/enquiries.service.module/enquiries.service";
import { Router, RouterModule } from "@angular/router";
import { BadgeComponent } from "../../badge/badge.component";
import { SafeUrlPipe } from "src/app/pipes/safe.url.pipe";
import { ActionTrackingDirective } from "src/app/directives/action-tracking.directive";
import { NavAnchorDirective } from "src/app/directives/nav-anchor.directive";
import { MobileAppBackButtonComponent } from "../mobile-app-back-button/mobile-app-back-button.component";
import { HistoryService } from "src/app/services/history.service.module/history.service";

class UserCampaignWithLink extends UserCampaign {
    uriName: string;
}

@Component({
    selector: 'nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar-shared.scss','./nav-bar.component.scss'],
    imports: [
        NgFor,NgIf,NgClass,
        RouterModule,
        NavBarIconButtonsComponent,
        ActionTrackingDirective,
        NavAnchorDirective,
        BadgeComponent, SafeUrlPipe, MobileAppBackButtonComponent],
    standalone: true,
    providers: [AuthorizationService]
})

export class NavBarComponent implements OnInit, OnDestroy {

    constructor(
        private messenger: Messenger,
        private router: Router,
        private authorizationService: AuthorizationService,
        private historyService: HistoryService,
        private platformService: PlatformHelpersService,
        private applicationStateService: ApplicationStateService,
        private applicationResources: ApplicationResourcesService,
        private enquiriesService: EnquiriesService)
        {

            this.hasBackStack = this.historyService.canGoBack();
            this.isMobileApp = this.applicationStateService.isMobileApp;

    }

    IsHamburgerOpen: boolean = false;
    badgeCount: number = 0;
    hasBackStack: boolean = false;
    isMobileApp: boolean = false;

    OnTapped() {
        this.IsHamburgerOpen = !this.IsHamburgerOpen;
    }


    private navigatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.NAVIGATED,
        On: () => {
            this.hasBackStack = this.historyService.canGoBack();
        }
    }

    private updateLogoButtonBadgeCountSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.UPDATE_LOGO_BUTTON_BADGE_COUNT,
        On: (data: UpdateBadgeCountMessageData) => {
            this.badgeCount = data.badgeCount;
        }
    }

    private menuButtonPerformActionSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.MENU_BUTTON_PERFORM_ACTION,
        On: (data: MenuButtonPerformActionMessageData) => {
            this.PerformActionForButton(data.performActionButtonId);
        }
    }

    private favouritesUpdatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.FAVOURITES_UPDATED,
        On: () => {
            this.favouritesBadgeCount = 0;
            var self = this;
            if (this.applicationStateService.me) {
                if (!this.applicationStateService.me.favourites) { this.favouritesBadgeCount = 0; }
                else {
                    this.favouritesBadgeCount = this.applicationStateService.me.favourites.length;
                }
            }
        }
    }

    private enquiriesUpdatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ENQUIRIES_UPDATED,
        On: () => {
            this.enquiriesBadgeCount = this.enquiriesService.GetEnquiries().filter(e => !e.isHidden).length;
        }
    }

    private savedSearchesUpdatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SAVED_SEARCHES_UPDATED,
        On: () => {
            this.searchesBadgeCount = 0;
            var self = this;
            if (this.applicationStateService.me) {
                var searches = this.applicationStateService.me.savedSearches;
                if (searches && searches.length > 0) {
                    searches.map(s => {
                        var bc = s.badgeCount;
                        if (!bc) { bc = 0; }
                        self.searchesBadgeCount += bc;
                    });
                }
            }
        }
    }

    private meStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
        On: (data: MeStateChangedMessageData) => {            
                this.updateMenu(data);
        }
    }


    updateMenu(data:any){

        if (data.me && data.me.campaigns && data.me.campaigns.length > 0) {
            this.ProcessCampaignsMenu(data.me.campaigns.map(c => {
                let result = new UserCampaignWithLink();
                result.campaignId = c.campaignId;
                result.name = c.name;
                result.type = c.type;
                result.uriName = encodeURIComponent(c.name);
                return result;
            }));
        }
        else {
            this.ProcessCampaignsMenu(null);
        }
    }
    



    private appClickedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.APP_CLICKED,
        On: () => {
            this.OnClicked(null, null);
        }
    }

    IsMenuVisible: boolean;

    Menu: Menu;

    get isMobile():boolean {
        return this.platformService.IsMobile;
    }

    private _searchesBadgeCount: number;
    set searchesBadgeCount(value: number) {
        this._searchesBadgeCount = value;
        this.SendUpdateLogoBadgeCountMessage();
        this.UpdateBadgeCount("searchesBadgeCount", value);
    }
    get searchesBadgeCount(): number {
        return this._searchesBadgeCount
    }

    private _favouritesBadgeCount: number;
    set favouritesBadgeCount(value: number) {
        this._favouritesBadgeCount = value;
        this.SendUpdateLogoBadgeCountMessage();
        this.UpdateBadgeCount("favouritesBadgeCount", value);
    }
    get favouritesBadgeCount(): number {
        return this._favouritesBadgeCount;
    }

    private _enquiriesBadgeCount: number;
    set enquiriesBadgeCount(value: number) {
        this._enquiriesBadgeCount = value;
        this.SendUpdateLogoBadgeCountMessage();
        this.UpdateBadgeCount("enquiriesBadgeCount", value);
    }
    get enquiriesBadgeCount(): number {
        return this._enquiriesBadgeCount;
    }

    

    ngOnInit(): void {
        //this.messenger.Subscribe(this.tappedHamburgerSubscriber);
        this.messenger.Subscribe(this.menuButtonPerformActionSubscriber);
        this.messenger.Subscribe(this.savedSearchesUpdatedSubscriber);
        this.messenger.Subscribe(this.favouritesUpdatedSubscriber);
        this.messenger.Subscribe(this.enquiriesUpdatedSubscriber);
        this.messenger.Subscribe(this.meStateChangedSubscriber);
        this.messenger.Subscribe(this.navigatedSubscriber);
        this.messenger.Subscribe(this.updateLogoButtonBadgeCountSubscriber);
        this.Menu = CopyObject(this.applicationResources.GetApplicationResources().menu);

        if (this.applicationStateService.me && this.applicationStateService.me.campaigns){
            this.updateMenu(this.applicationStateService.me.campaigns);
        }

    }

    ngOnDestroy() {
        //this.messenger.Unsubscribe(this.tappedHamburgerSubscriber);
        this.messenger.Unsubscribe(this.menuButtonPerformActionSubscriber);
        this.messenger.Unsubscribe(this.updateLogoButtonBadgeCountSubscriber);
        this.messenger.Unsubscribe(this.navigatedSubscriber);
    }

    OnClicked(e: Event, item?: MenuItem) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        else{
            return;
        }
        
        let clickedOnOpenItem = false;
        if (item) {
            clickedOnOpenItem = item.isCurrentSelection;
        }else{
            this.IsHamburgerOpen = false;
        }
        this.ClearMenuCurrentSelection();
        if (clickedOnOpenItem) { return; }

        if (item) {
            if (item.performActionButtonId) {
                this.messenger.Unsubscribe(this.appClickedSubscriber);
                this.PerformActionForButton(item.performActionButtonId);
            }
            else {
                item.isCurrentSelection = true;
                this.messenger.Subscribe(this.appClickedSubscriber);
            }
        }
    }

    private ClearMenuCurrentSelection() {
        for (let i of this.Menu.items) {
            i.isCurrentSelection = false;
        }
    }

    private PerformActionForButton(performActionButtonId: string): void {
        switch (performActionButtonId) {
            case "FAVOURITES":
                if (!this.ShowSignUpIfLoggedOut(performActionButtonId)) {
                    this.IsHamburgerOpen = false;
                    this.router.navigate(['/favourites']);
                    
                }
                break;
            case "ENQUIRIES":
                if (!this.ShowSignUpIfLoggedOut(performActionButtonId)) {
                    this.IsHamburgerOpen = false;
                    this.router.navigate(['/enquiries']);
                    
                }
                break;
        }
    }

    // Will return true if showed sign up
    private ShowSignUpIfLoggedOut(performActionButtonId: string): boolean {
        if (!this.authorizationService.isLoggedIn) {
            var loginMessageData: ShowLoginMessageData;
            if (performActionButtonId === "FAVOURITES") {
                this.applicationStateService.StorePostLoginReturnUrl("/favourites");
                loginMessageData = {
                    showOverlay: true,
                    isRegistration: true,
                    explicitRedirectUrl: "/favourites",
                    registerText: [
                        "Placebuzz has much more to offer",
                        "Sign up to",
                        "keep",
                        "all your favourite properties in one place",
                    ]
                };
            }
            else if (performActionButtonId === "ENQUIRIES") {
                this.applicationStateService.StorePostLoginReturnUrl("/enquiries");
                loginMessageData = {
                    showOverlay: true,
                    isRegistration: true,
                    explicitRedirectUrl: "/enquiries",
                    registerText: [
                        "Placebuzz has much more to offer",
                        "Sign up to",
                        "keep",
                        "all your property enquiries in one place",
                    ]
                };
            }
            this.messenger.Send({
                messageType: MESSAGE_TYPES.SHOW_LOGIN,
                messageData: loginMessageData
            });
            return true;
        }
        return false;
    }

    private ProcessCampaignsMenu(campaigns: Array<UserCampaignWithLink>) {
        if (!this.Menu || !this.Menu.items) { return; }
        var existingCampaignsMenu = this.Menu.items.find(i => i.actionTrackingLabel === "NavBar-MemberOrAdmin");
        if (campaigns) {
            if (existingCampaignsMenu) { return; }

            var partnerAreaMenuItem: MenuItem;

            if (campaigns && campaigns.find(p=>p.type=="HLL")){
                    partnerAreaMenuItem = {
                        text: "Member portal",
                        actionTrackingLabel: "NavBar-MemberOrAdmin",
                        link:"/members",
                        subItems: []                        
                    };
            }
            else{   
                var partnerAreaMenuItem: MenuItem = {
                    text: "Partners",
                    secondaryNavBarAssociationId: "partnerAreaSecondaryNavBar",
                    actionTrackingLabel: "NavBar-MemberOrAdmin",
                    subItems: []
                };
            }


            // Secondary menus
            for (let campaign of campaigns) {
                if (campaign.type === "CPL" || campaign.type === "PPC") {
                    let reportPath = campaign.type === "CPL" ? "activity-report" : "ppc-activity-report";
                    // CPL Partners (Zoopla / OnTheMarket etc)
                    partnerAreaMenuItem.subItems.push({
                        type: SubMenuItemTypes.Separator,
                        hideOnMobile: true
                    })
                    partnerAreaMenuItem.subItems.push({
                        type: SubMenuItemTypes.Link,
                        fontIconClass: "icon-Document",
                        iconClasses: "nav-bar-icon-button",
                        link: "/partner-area/" + reportPath + "?campaignId=" + campaign.campaignId,
                        actionTrackingLabel: "NavBar-Partner Area-Activity Report",
                        caption: "Activity report" + " - " + campaign.name
                    });
                }
            }
            // this.secondaryMenus.push(this.ConstructSubMenu(partnerAreaMenuItem));
            this.Menu.items.push(partnerAreaMenuItem);
        }
        else {
            if (existingCampaignsMenu) {
                var indexOfExistingCampaignsMenu = this.Menu.items.indexOf(existingCampaignsMenu);
                this.Menu.items.splice(indexOfExistingCampaignsMenu, 1);
            }
        }
    }


    private UpdateBadgeCount(forProperty: string, value: number) {
        if (this.Menu) {
            var menuItem = this.Menu.items.find(i => i.badgeCountPropertyName === forProperty);
            if (menuItem) {
                menuItem.badgeCount = value;
            }
        }
    }

    private SendUpdateLogoBadgeCountMessage() {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.UPDATE_LOGO_BUTTON_BADGE_COUNT,
            messageData: new UpdateBadgeCountMessageData(this.searchesBadgeCount + this.favouritesBadgeCount + this.enquiriesBadgeCount)
        });
    }
}

