import { GqlQueryArgument, GqlQueryPart } from "src/app/services/graphql.module/model";
import { MortgageEstimate } from "../../infrastructure/model/mortgageEstimate";
import { CreateGqlQueryArguments } from "../create.query";

export const PropertyListingDetailsQuery = `
  propertyListingDetails(listingId:$listingId, mortgageEstimateInput: $mortgageEstimateInput, campaignId:$campaignId){
    httpStatusCode,
    title,
    address,
    numericPrice,
    postcode,
    price,
    priceModifier,
    similarPropertiesSearchUrl,
    hasShareMyDetailsWithPartner,
    shareMyDetailsWithPartnerText,
    photos,
    floorPlans,
    firstPublishedDate,
    isPlacebuzzProperty,
    features{
        title,
        badgeCount,
        description,
        iconUrl
    }
    isNew,
    campaignId,
    listingId,
    listingTypeId,
    description,
    isPublished,
    agentName,
    agentAddress,
    agentPhoneNumber,
    listerLogoUrl  ,
    poweredByUrl,
    latitude,
    longitude,
    tenancyInfo,
    documents{
      isEnabled,
      url,
      value,
      rel,
      title
    },
    robots{
      metaTitle
      metaDescription
      metaLanguage
      metaRobots,
      canonicalUrl
    },
    similarListings{
        price,
        weeklyPrice,
        priceModifier,
        title,
        detailsUrl,
        photos,
        subTitle,
        address,
        eCommerceTracking{
          id,
          name,
          brand,
          dimension1,
          category,
          coupon,
          variant,
          price,
          event,
          affiliation
        }
    },
    mortgageEstimate{
      propertyPrice,
      currencySymbol,
      deposit,
      annualInterest,
      repaymentPeriod,
      repaymentPeriods{
        key,
        value
      },
      estimatedMonthlyMortgage,
      estimatedMonthlyMortgageInfo,
      isAboveVisibilityThreshold
    },
    shareUrl,
    detailsUrl,
    eCommerceTracking{
      id,
      name,
      brand,
      dimension1,
      category,
      coupon,
      variant,
      price,
      event,
      affiliation
    },
    virtualTour,
    isRedirectToPartner,
    partnerRedirectUrl,
    showEnquiryForm
  }
`;
export const PropertyListingDetailsQueryArgs: Array<GqlQueryArgument> = [
  { variableName: "$listingId", variableType: "String" },
  { variableName: "$mortgageEstimateInput", variableType: "mortgageEstimateInput" },
  { variableName: "$campaignId", variableType: "Int" },
];
export function CreatePropertyListingDetailsQueryPart(listingId: string, mortgageEstimateInput: MortgageEstimate, campaignId:number): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: PropertyListingDetailsQuery,
    arguments: CreateGqlQueryArguments(PropertyListingDetailsQueryArgs, [listingId, mortgageEstimateInput, campaignId])
  };
  return result;
};