import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MeStateChangedMessageData, ShowLoginMessageData } from "src/app/infrastructure/message.data.types";
import { Me } from "src/app/infrastructure/model/me";
import { CircleButtonComponent } from "src/app/controls/circle-button/circle-button.component";
import { NgIf } from "@angular/common";
import { Router, RouterLink } from "@angular/router";
import { IconButtonComponent } from "../../modules/isolated.components/icon-button/icon-button.component";
import { ActionTrackingDirective } from "../../directives/action-tracking.directive";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { HistoryService } from "src/app/services/history.service.module/history.service";


@Component({
    selector: 'account-button',
    templateUrl: './account-button.component.html',
    styleUrls: ['./account-button.component.scss'],
    standalone: true,
    imports: [IconButtonComponent, CircleButtonComponent, NgIf, ActionTrackingDirective, RouterLink]
})

export class AccountButtonComponent implements OnInit, OnDestroy {
    @Input() initial: string;

    isUserLoggedIn: boolean = false;
    isPopupVisible: boolean = false;

    private meStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
        On: (data: MeStateChangedMessageData) => {
            if (data) {
                this.OnMeStateChanged(data.me);
            }
        }
    }

    private appClickedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.APP_CLICKED,
        On: () => {
            this.isPopupVisible = false;
        }
    }

    private closePopuSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.CLOSE_POPUP,
        On: () => {
            this.isPopupVisible = false;
        }
    }

    constructor(
        private messenger: Messenger,
        private applicationStateService: ApplicationStateService,
        private router: Router,
        private historyService: HistoryService
    ) {
        
    }

    ngOnInit() {
        this.messenger.Subscribe(this.meStateChangedSubscriber);
        this.messenger.Subscribe(this.appClickedSubscriber);
        this.messenger.Subscribe(this.closePopuSubscriber);
        
        if (this.applicationStateService.me){
            this.OnMeStateChanged(this.applicationStateService.me);
        }
        
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.meStateChangedSubscriber);
        this.messenger.Unsubscribe(this.closePopuSubscriber);
        this.messenger.Unsubscribe(this.appClickedSubscriber);
    }


    OnClick(e) {
        e.stopPropagation();
        this.isPopupVisible = !this.isPopupVisible;
    }

    ClickedSignup() {
        this.SendLoginSignUpMessage(true);
    }

    ClickedLogin() {
        this.SendLoginSignUpMessage(false);
    }

    ClickedLogout() {
        this.applicationStateService.LogoutAndClearMe();
        this.historyService.clearHistory();
        this.router.navigate(["/"]);
    }

    private SendLoginSignUpMessage(isRegister: boolean) {
        this.applicationStateService.StorePostLoginReturnUrl("");
        var loginMessageData: ShowLoginMessageData = {
            showOverlay: true,
            isRegistration: isRegister
        };

        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_LOGIN,
            messageData: loginMessageData
        });
    }

    private OnMeStateChanged(me: Me) {
        if (me) {
            if (me.email) {
                this.isUserLoggedIn = true;
                if (me.fullName) {
                    this.initial = me.fullName[0];
                }
                else {
                    this.initial = me.email[0];
                }
            }
        }
        else {
            this.isUserLoggedIn = false;
        }
    }
}